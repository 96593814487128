import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const clubDefinition: EntityDefinition = createEntityDefinition({
  name: 'club',
  label: 'Club',
  pages: {
    view: {
      childLists: [
        {
          name: 'races',
          childField: 'club',
          title: 'Races',
          type: 'race',
          filterBy: [{ childFilter: 'clubId_equal', entityField: 'id' }],
          overrideFields: ['name', 'date']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      },
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'code',
      label: 'Code',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('club', clubDefinition);
