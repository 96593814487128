import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { FastTrackMeetCreateButton } from './FastTrackMeetCreateButton';
import { MeetFromFastTrackMeetButton } from './MeetFromFastTrackMeetButton';

export const fastTrackMeetDefinition: EntityDefinition = createEntityDefinition({
  name: 'fastTrackMeet',
  label: 'Fast Track Meet',
  pages: {
    list: {
      canCreate: false,
      actions: [FastTrackMeetCreateButton]
    },
    view: {
      canEdit: false,
      actions: [MeetFromFastTrackMeetButton]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'track',
      label: 'Track Id',
      type: 'string',
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'date',
      label: 'Date',
      type: 'string'
    },
    {
      name: 'timeslot',
      label: 'Time slot',
      type: 'string'
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('fastTrackMeet', fastTrackMeetDefinition);
