import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { SyncFastTrackDogButton } from './SyncFastTrackDogButton';
import { GreyhoundFromFastTrackDogButton } from './GreyhoundFromFastTrackDogButton';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const fastTrackDogDefinition: EntityDefinition = createEntityDefinition({
  name: 'fastTrackDog',
  label: 'Fast Track Dog',
  pages: {
    view: {
      canEdit: false,
      actions: [GreyhoundFromFastTrackDogButton, SyncFastTrackDogButton]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'dogName',
      label: 'Name',
      type: 'string',
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      type: 'string',
      editOptions: {
        enabled: false
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'trainer',
      label: 'Trainer',
      type: 'string',
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'nameAssigned',
      label: 'Name Assigned',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'colour',
      label: 'Colour',
      type: 'string',
      editOptions: {
        enabled: false
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'sex',
      label: 'Sex',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'state',
      label: 'State',
      type: 'string',
      editOptions: {
        enabled: false
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'whelped',
      label: 'Whelped',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'sire',
      label: 'Sire',
      type: 'string',
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'dam',
      label: 'Dam',
      type: 'string',
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'breeder',
      label: 'Breeder',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'career',
      label: 'Career',
      type: 'string',
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'prizeMoney',
      label: 'Prize Money',
      type: 'string',
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('fastTrackDog', fastTrackDogDefinition);
