import React, { useEffect, useState } from 'react';
import { Paper, Table, useMediaQuery, useTheme, CardHeader, Divider } from '@material-ui/core';
import { ColumnDirection, ColumnVisibility, FilterChanges } from './tableTypes';
import { SiteTableHeader } from './SiteTableHeader';
import { SiteTableBody } from './SiteTableBody';
import { SiteTableFooter } from './SiteTableFooter';
import { DocumentNode, useQuery } from '@apollo/client';
import { DataZone } from '../zones/DataZone';
import { SiteTableActionBar } from './SiteTableActionBar';
import { buildColumnVisibilities, buildOrderByParts } from './tableService';
import { EntityDefinition } from '../entity/entityTypes';
import { getNameCamelPlural } from '../entity/entityHelper';
import { CustomTableAction, StandardTableActions } from './SiteTableTypes';

interface Props {
  entityDefinition: EntityDefinition;
  query: DocumentNode;
  defaultRowPerPage?: number;
  rowsPerPageOptions?: number[];
  title?: string;
  columnVisibilityOverrides?: string[];
  filterOverrides?: object;
  onEmpty?: 'hidden' | 'none' | 'padded';
  createParams?: Record<string, string>;
  standardTableActions?: StandardTableActions;
  orderByOverride?: string;
  customTableActions?: CustomTableAction[];
}

export function SiteTable(props: Props) {
  const {
    entityDefinition,
    query,
    rowsPerPageOptions = [8, 10, 15, 25, 50],
    defaultRowPerPage = 15,
    standardTableActions,
    title,
    columnVisibilityOverrides = [],
    filterOverrides = {},
    onEmpty = 'none',
    createParams,
    orderByOverride,
    customTableActions
  } = props;
  const queryField = `${getNameCamelPlural(entityDefinition.name)}`;
  const columns = entityDefinition.fields;
  const defaultOrderBy = orderByOverride ? orderByOverride : entityDefinition.pages.list.orderBy;

  // setup column visibilities
  const [columnVisibilities, setColumnVisibilities] = useState(
    buildColumnVisibilities(columns, columnVisibilityOverrides)
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
  useEffect(() => {
    setColumnVisibilities(
      buildColumnVisibilities(columns, columnVisibilityOverrides, isSmallScreen, isExtraSmallScreen)
    );
    // eslint-disable-next-line
  }, [isSmallScreen, isExtraSmallScreen, columns]);

  // setup search and filters
  const [filters, setFilters] = useState(filterOverrides);

  // setup order by
  const orderByParts = buildOrderByParts(defaultOrderBy);
  const [orderBy, setOrderBy] = useState<string | undefined>(orderByParts.orderBy);
  const [orderByColumnId, setOrderByColumnId] = useState(orderByParts.orderByColumn);
  const [orderDirection, setOrderDirection] = useState<ColumnDirection>(orderByParts.orderByDirection);

  // setup paging
  const [afterCursor, setAfterCursor] = useState<string | undefined | null>();
  const [beforeCursor, setBeforeCursor] = useState<string | undefined | null>();
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const first = !beforeCursor && !isLastPage ? rowsPerPage : null;
  const last = beforeCursor || isLastPage ? rowsPerPage : null;

  useEffect(() => {
    if (isSmallScreen) {
      setRowsPerPage(10);
    }
    if (isExtraSmallScreen) {
      setRowsPerPage(8);
    }
  }, [isSmallScreen, isExtraSmallScreen]);

  // set ordering functions
  const handleRequestSort = (event: React.MouseEvent<HTMLButtonElement>, columnId: string) => {
    const anOrderByColumnId = columnId;
    let anOrderDirection: ColumnDirection = 'desc';

    if (anOrderByColumnId === columnId && orderDirection === 'desc') {
      anOrderDirection = 'asc';
    }

    const anOrderBy = `${anOrderByColumnId}_${anOrderDirection.toUpperCase()}`;
    setOrderBy(anOrderBy);
    setOrderDirection(anOrderDirection);
    setOrderByColumnId(anOrderByColumnId);
    setAfterCursor(undefined);
    setBeforeCursor(undefined);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setAfterCursor(undefined);
    setBeforeCursor(undefined);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    requestedPage: number,
    totalItems: number,
    requestedStartCursor?: string | null,
    requestedEndCursor?: string | null
  ) => {
    const lastPage = Math.max(0, Math.ceil(totalItems / rowsPerPage) - 1);
    if (requestedPage === 0) {
      setBeforeCursor(undefined);
      setAfterCursor(undefined);
      setIsLastPage(false);
    } else if (requestedPage === lastPage) {
      setBeforeCursor(undefined);
      setAfterCursor(undefined);
      setIsLastPage(true);
    } else if (page > requestedPage) {
      setBeforeCursor(requestedStartCursor);
      setAfterCursor(undefined);
      setIsLastPage(false);
    } else if (page < requestedPage) {
      setBeforeCursor(undefined);
      setAfterCursor(requestedEndCursor);
      setIsLastPage(false);
    }
    setPage(requestedPage);
  };

  const handleFilterChange = (filterChanges: FilterChanges) => {
    setBeforeCursor(undefined);
    setAfterCursor(undefined);
    setPage(0);

    if (filterChanges.added) {
      setFilters({ ...filters, ...filterChanges.added });
    }

    if (filterChanges.removed) {
      const clearedFilters: any = {
        ...filters
      };
      Object.keys(filterChanges.removed).forEach((key) => {
        delete clearedFilters[key];
      });
      setFilters(clearedFilters);
    }
  };

  const handleDateRangeChange = (from?: Date, to?: Date) => {
    const dateColumn = columns.find((column) => column.type === 'date' && column.tableOptions.canFilter);
    if (dateColumn) {
      if (!from || !to) {
        const clearedFilters: any = {
          ...filters
        };
        if (!from) delete clearedFilters[`${dateColumn.name}_gte`];
        if (!to) delete clearedFilters[`${dateColumn.name}_lt`];
        setFilters({
          ...clearedFilters
        });
      } else {
        setFilters({
          ...filters,
          [`${dateColumn.name}_gte`]: from.toISOString(),
          [`${dateColumn.name}_lt`]: to.toISOString()
        });
      }
    }
  };

  // load data
  const { loading, error, data, refetch } = useQuery(query, {
    variables: {
      first,
      last,
      beforeCursor,
      afterCursor,
      orderBy,
      filters
    },
    fetchPolicy: 'cache-and-network'
  });

  const entities = data?.[queryField] ?? [];
  const pageInfo = entities?.pageInfo;
  const edges = entities?.edges;
  const nodes = edges?.map((edge: any) => edge.node) ?? [];
  const total = pageInfo?.total ?? 0;
  const startCursor = pageInfo?.startCursor;
  const endCursor = pageInfo?.endCursor;

  return (
    <DataZone error={error}>
      {onEmpty === 'hidden' && nodes.length === 0 ? null : (
        <Paper>
          {title ? (
            <>
              <CardHeader title={title} />
              <Divider />
            </>
          ) : null}
          <SiteTableActionBar
            entityDefinition={entityDefinition}
            filters={filters}
            updateFilters={handleFilterChange}
            rows={nodes}
            columnVisibilities={columnVisibilities}
            setColumnVisibilities={(cvs: Array<ColumnVisibility>) => {
              setColumnVisibilities(cvs);
            }}
            onDateRangeChange={handleDateRangeChange}
            reload={() => refetch()}
            createParams={createParams}
            standardTableActions={standardTableActions}
            customTableActions={customTableActions}
          />
          <Table>
            <SiteTableHeader
              entityDefinition={entityDefinition}
              columnVisibilities={columnVisibilities}
              orderByColumnId={orderByColumnId}
              orderDirection={orderDirection}
              onRequestSort={handleRequestSort}
            />
            <SiteTableBody
              entityDefinition={entityDefinition}
              columnVisibilities={columnVisibilities}
              rows={nodes}
              loading={loading}
              rowsPerPage={rowsPerPage}
              padHeight={onEmpty === 'padded'}
            />
            <SiteTableFooter
              entityDefinition={entityDefinition}
              pagingOptions={{
                rowsPerPageOptions,
                total,
                rowsPerPage,
                page,
                onChangePage: (event, pageNumber) => {
                  handleChangePage(event, pageNumber, total, startCursor, endCursor);
                },
                onChangeRowsPerPage: handleChangeRowsPerPage
              }}
            />
          </Table>
        </Paper>
      )}
    </DataZone>
  );
}
