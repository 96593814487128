import React from 'react';
import rug1 from './rug1.svg';
import rug2 from './rug2.svg';
import rug3 from './rug3.svg';
import rug4 from './rug4.svg';
import rug5 from './rug5.svg';
import rug6 from './rug6.svg';
import rug7 from './rug7.svg';
import rug8 from './rug8.svg';
import rug9 from './rug9.svg';
import rug10 from './rug10.svg';

export interface RugIconProps {
  rugNumber: number;
}

const rugImages = [rug1, rug2, rug3, rug4, rug5, rug6, rug7, rug8, rug9, rug10];

export function RugIcon(props: RugIconProps) {
  const { rugNumber } = props;
  if (rugNumber > 0 && rugNumber < 11) {
    return <img src={rugImages[rugNumber - 1]} alt="box number" width="24" height="24" />;
  }
  return <div>{rugNumber}</div>;
}
