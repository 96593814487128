import { FieldDefinition, FieldDefinitionEnumTypeItem } from './entityTypes';
import { valueToSentenceCase } from '../util/stringService';

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum State {
  ACT = "ACT",
  NSW = "NSW",
  NT = "NT",
  QLD = "QLD",
  SA = "SA",
  TAS = "TAS",
  VIC = "VIC",
  WA = "WA",
}

export function getEnumItemsInSentenceCase(enumItems: string[]): FieldDefinitionEnumTypeItem[] {
  return enumItems.map((value) => {
    return {
      label: valueToSentenceCase(value),
      value
    };
  });
}

export function getStateEnumItems(): FieldDefinitionEnumTypeItem[] {
  return [
    { label: 'Australian Capital Territory', value: State.ACT },
    { label: 'New South Wales', value: State.NSW },
    { label: 'Northern Territory', value: State.NT },
    { label: 'Queensland', value: State.QLD },
    { label: 'South Australia', value: State.SA },
    { label: 'Tasmania', value: State.TAS },
    { label: 'Victoria', value: State.VIC },
    { label: 'Western Australia', value: State.WA }
  ];
}

export function getEnumItemForField(
  fieldDefinition: FieldDefinition,
  value: string
): FieldDefinitionEnumTypeItem | null {
  return fieldDefinition.typeOptions.enum?.items.find((item) => item.value === value) ?? null;
}

export function getEnumItemLabel(fieldDefinition: FieldDefinition, value: string): string {
  return getEnumItemForField(fieldDefinition, value)?.label ?? value;
}
