import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { EntityDefinition } from '../../entity/entityTypes';
import SyncIcon from '@material-ui/icons/Sync';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { buildEntityFields } from '../../entity/pages/entityQueryBuilder';

interface Props {
  entityDefinition: EntityDefinition;
  entity: object;
}

export function buildSyncQuery(entityDefinition: EntityDefinition) {
  const entityFields = buildEntityFields(entityDefinition);
  return gql(`mutation SyncGreyhound($input: GreyhoundUpdateFromFastTrackInput!) {
    greyhoundUpdateFromFastTrack(input: $input) {
      greyhound {
        ${entityFields}
      }
    }
  }`);
}

export function SyncGreyhoundButton(props: Props) {
  const { entity, entityDefinition } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [syncAction, { loading }] = useMutation(buildSyncQuery(entityDefinition));
  const [open, setOpen] = React.useState(false);
  const entityId = (entity as any).id;
  if (!entityId) {
    return null;
  }
  const handleClickOpen = async () => {
    setErrorMessage(undefined);
    setOpen(true);
    try {
      await syncAction({ variables: { input: { id: entityId } } });
      setOpen(false);
    } catch (error) {
      console.error('mutation error', error);
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button disabled={open} color="primary" onClick={handleClickOpen} variant="contained" startIcon={<SyncIcon />}>
        Sync
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog" aria-describedby="confirm dialog">
        {errorMessage ? (
          <>
            <DialogTitle id="confirm-dialog-error">Error</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-error-description">{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen} color="primary" autoFocus>
                Retry
              </Button>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <LoadingSpinner minHeight={52} minWidth={500} message="Getting updated details from fast track..." />
        )}
      </Dialog>
    </>
  );
}
