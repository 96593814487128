import { EntityDefinition } from '../entity/entityTypes';
import { createEntityDefinition } from '../entity/entityHelper';
import { registerEntityDefinition } from '../entity/entityDefinitionService';

export const externalReferenceDefinition: EntityDefinition = createEntityDefinition({
  name: 'externalReference',
  label: 'External Reference',
  pages: {
    list: {
      canCreate: false
    },
    view: {
      canEdit: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      viewOptions: {
        selfLink: true
      },
      tableOptions: {
        sortable: true
      }
    },
    {
      name: 'internalId',
      label: 'Internal id',
      type: 'string',
      tableOptions: {
        canFilter: true,
        sortable: true
      }
    },
    {
      name: 'internalEntityType',
      label: 'Internal entity type',
      type: 'string',
      tableOptions: {
        canFilter: true,
        sortable: true
      }
    },
    {
      name: 'externalId',
      label: 'External id',
      type: 'string',
      tableOptions: {
        canFilter: true,
        sortable: true
      }
    },
    {
      name: 'externalEntityType',
      label: 'External entity type',
      type: 'string',
      tableOptions: {
        canFilter: true,
        sortable: true
      }
    },
    {
      name: 'externalSystem',
      label: 'External system',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'externalSystem',
          id: 'id',
          value: 'name',
          parentField: 'externalSystemId'
        }
      }
    },
    {
      name: 'externallyUpdatedAt',
      label: 'Externally updated',
      type: 'date',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('externalReference', externalReferenceDefinition);
