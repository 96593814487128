import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import Ranker from './ranker/Ranker';
import { theme } from './ranker/RankerTheme';
import history from './navigation/history';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { ErrorZone } from './zones/ErrorZone';
import { AppContextProvider } from './ranker/AppContext';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from './graphql/apolloClientHelper';

export const onRedirectCallback = (appState: { targetUrl: string }) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

function App() {
  const graphqlUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL ?? 'http://localhost:3030/graphql';
  const apolloDevToolsEnabled = process.env.NODE_ENV !== 'production';
  return (
    <ErrorZone>
      <AppContextProvider>
        <ApolloProvider client={createApolloClient({ graphqlUrl, apolloDevToolsEnabled })}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <Router history={history}>
                <Ranker />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ApolloProvider>
      </AppContextProvider>
    </ErrorZone>
  );
}

export default App;
