import React from 'react';
import { FieldDefinition } from '../entityTypes';
import { InputAdornment, TextField } from '@material-ui/core';
import { IntegerField } from '../../form/IntegerField';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
  formControl: any;
}

export function EntityEditIntegerField(props: Props) {
  const { fieldDefinition, entity, formRef, formErrors } = props;
  const endAdornment = fieldDefinition.typeOptions.integer?.suffix ? (
    <InputAdornment position="end">{fieldDefinition.typeOptions.integer?.suffix}</InputAdornment>
  ) : null;
  return (
    <TextField
      name={fieldDefinition.name}
      defaultValue={entity[fieldDefinition.name]}
      fullWidth
      required={fieldDefinition.editOptions.mandatory}
      label={fieldDefinition.label}
      variant="outlined"
      inputRef={formRef}
      error={formErrors[fieldDefinition.name] != null}
      helperText={formErrors[fieldDefinition.name]?.message}
      InputProps={{
        inputComponent: IntegerField as any,
        endAdornment,
        inputProps: {
          'aria-autocomplete': 'none',
          'data-lpignore': 'true'
        }
      }}
    />
  );
}
