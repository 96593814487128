import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from './auth_config.json';
import history from '../navigation/history';

let auth0Client: Auth0Client;

export function onRedirectCallback(appState: { targetUrl: string }) {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
}

export async function setupAuth0Client(): Promise<Auth0Client> {
  try {
    auth0Client = await createAuth0Client({
      domain: authConfig.domain,
      client_id: authConfig.clientId,
      redirect_uri: window.location.origin,
      logoutUrl: window.location.origin,
      cacheLocation: 'localstorage'
    });

    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      const { appState } = await auth0Client.handleRedirectCallback();
      onRedirectCallback(appState);
    }

    return auth0Client;
  } catch (error) {
    console.error('Failed to start auth0 client', error);
    throw error;
  }
}

export async function getAuth0Client() {
  if (!auth0Client) {
    return await setupAuth0Client();
  }
  return auth0Client;
}

export async function signup() {
  const client = await getAuth0Client();
  await client.loginWithRedirect({ screen_hint: 'signup' });
}

export async function login() {
  const client = await getAuth0Client();
  await client.loginWithRedirect();
}

export async function logout() {
  const client = await getAuth0Client();
  await client.logout({ returnTo: window.location.origin });
}
