import { useMutation, gql } from '@apollo/client';
import { DatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { CustomTableActionProps } from '../../table/SiteTableTypes';
import { STANDARD_DATE_FORMAT } from '../../util/dateService';
import { DataZone } from '../../zones/DataZone';
import AddIcon from '@material-ui/icons/Add';

const fastTrackCreateQuery = gql(`
mutation($input: FastTrackMeetsUpdateInput!) {
    updateFastTrackMeets(input: $input) {
      fastTrackMeets {
        id
        track
        date
        timeslot
        createdAt
        updatedAt
      }
    }
  }
`);

export function FastTrackMeetCreateButton(props: CustomTableActionProps) {
  const [open, setOpen] = React.useState(false);
  const [lookupDate, setLookupDate] = useState(dayjs(new Date()).startOf('day').toDate().toISOString());
  const [createAction, { loading, error }] = useMutation(fastTrackCreateQuery);
  const { reload } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    handleClose();
    try {
      await createAction({ variables: { input: { date: lookupDate } } });
      reload();
    } catch (error) {
      console.error('mutation error', error);
    }
  };

  return (
    <>
      <Button color="primary" variant="contained" size="small" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="fast-track-meet-create-dialog"
        aria-describedby="fast track meet create dialog"
      >
        <DataZone
          loading={loading}
          error={error}
          errorType="errorMessage"
          loadingMessage={'Creating...'}
          loadingType="spinner"
          minWidth={500}
          minHeight={52}
        >
          <DialogTitle id="fast-track-meet-create-dialog-id">Create meetings</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item>
                <DatePicker
                  key="date-range-picker-meet-date"
                  value={lookupDate}
                  fullWidth
                  label="Meet date"
                  inputVariant="outlined"
                  clearable={false}
                  format={STANDARD_DATE_FORMAT}
                  onChange={(date) => {
                    if (date !== null && date !== undefined) {
                      setLookupDate(date.toDate().toISOString());
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </DataZone>
      </Dialog>
    </>
  );
}
