import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormFieldDefinition } from './FormFieldDefinition';
import { RadioListField } from './RadioListField';

interface Props {
  fieldDefinition: FormFieldDefinition;
  formRef: any;
  formErrors: any;
  formControl: any;
  watch: any;
  setValue: any;
}

export function FormFieldForType(props: Props) {
  const { fieldDefinition, formErrors, formControl } = props;
  switch (fieldDefinition.type) {
    case 'radioList':
      return <RadioListField fieldDefinition={fieldDefinition} formControl={formControl} formErrors={formErrors} />;
    default:
      return <></>;
  }
}

const useStyles = makeStyles((theme) => ({
  lineItem: {
    flexGrow: 1
  }
}));

export function FormField(props: Props) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item className={classes.lineItem}>
        <FormFieldForType {...props} />
      </Grid>
    </Grid>
  );
}
