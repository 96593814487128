import { Button, PropTypes } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  label: string;
  color?: PropTypes.Color;
  icon?: ReactNode;
  location?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
}

export function NavButton(props: Props) {
  const history = useHistory();
  const { label, location, color, icon, size = 'medium', variant = 'contained', onClick } = props;

  const buttonClick = onClick
    ? onClick
    : () => {
        if (location) {
          history.push(location);
        }
      };

  return (
    <Button onClick={buttonClick} size={size} variant={variant} startIcon={icon} color={color}>
      {label}
    </Button>
  );
}
