import dayjs from 'dayjs';
import { range } from 'lodash';

export interface DatePair {
  start: Date;
  end: Date;
}

export const MONTH_FORMAT = 'MMM YYYY';
export const SHORT_ISO = 'DD-MM-YYYY';
export const STANDARD_DATE_FORMAT = 'D MMMM YYYY';
export const STANDARD_DATE_TIME_FORMAT = 'D MMMM YYYY h:m A';
const minYear = 2012;

export function rankingDateSet(date: Date) {
  const years = range(minYear, date.getFullYear()+1, 1).reverse().map((year: number) => {
    const pointDate = new Date(year, date.getMonth(), date.getDate());
    const start = dayjs(pointDate).startOf('year').toDate();
    const end = dayjs(pointDate).endOf('year').toDate();
    return {
      label: `${year}`,
      start,
      end
    };
  });

  const finYears = range(minYear, date.getFullYear()+1, 1).reverse().map((year: number) => {
    const finYear = getFinancialYearFromDate(new Date(year, date.getMonth(), date.getDate()));
    return {
      label: formatDateRange(finYear.start,finYear.end),
      start: finYear.start,
      end: finYear.end
    };
  });

  return [
    years,
    finYears
  ]
}

export function getDateSet(date: Date) {
  const startOfThisMonth = dayjs(date).startOf('month').toDate();
  const endOfThisMonth = dayjs(date).endOf('month').toDate();
  const startOfLastMonth = dayjs(date).subtract(1, 'month').startOf('month').toDate();
  const endOfLastMonth = dayjs(date).subtract(1, 'month').endOf('month').toDate();
  const startOfThisYear = dayjs(date).startOf('year').toDate();
  const endOfThisYear = dayjs(date).endOf('year').toDate();
  const startOfLastYear = dayjs(date).subtract(1, 'year').startOf('year').toDate();
  const endOfLastYear = dayjs(date).subtract(1, 'year').endOf('year').toDate();
  const thisFinYearSet = getFinancialYearFromDate(date);
  const lastFinYearSet = getFinancialYearFromDate(dayjs(date).subtract(1, 'year').toDate());
  return [
    [
      {
        label: 'This month',
        start: startOfThisMonth,
        end: endOfThisMonth
      },
      {
        label: 'Last month',
        start: startOfLastMonth,
        end: endOfLastMonth
      }
    ],
    [
      {
        label: 'This year',
        start: startOfThisYear,
        end: endOfThisYear
      },
      {
        label: 'Last year',
        start: startOfLastYear,
        end: endOfLastYear
      }
    ],
    [
      {
        label: 'This financial year',
        ...thisFinYearSet
      },
      {
        label: 'Last financial year',
        ...lastFinYearSet
      }
    ]
  ];
}

export function getFinancialYearFromDate(date: Date): DatePair {
  const midYear = dayjs(date).set('month', 6).set('date', 1).startOf('day');
  if (midYear.isAfter(date)) {
    return {
      start: midYear.clone().subtract(12, 'month').toDate(),
      end: midYear.subtract(1, 'day').endOf('day').toDate()
    };
  } else {
    return {
      start: midYear.toDate(),
      end: midYear.clone().add(12, 'month').subtract(1, 'day').endOf('day').toDate()
    };
  }
}

export function getDateRangeLabel(from?: Date, to?: Date): string {
  if (!from && !to) {
    return 'All time';
  }
  if (!from) {
    return `Before ${dayjs(to).format(MONTH_FORMAT)}`;
  }
  if (!to) {
    return `After ${dayjs(from).format(MONTH_FORMAT)}`;
  }

  return `${dayjs(from).format(MONTH_FORMAT)} - ${dayjs(to).format(MONTH_FORMAT)}`;
}

export function formatDateRange(from: Date, to: Date): string {
  return `${dayjs(from).format(MONTH_FORMAT)}–${dayjs(to).format(MONTH_FORMAT)}`
}

export function formatToStandardDateTimeString(date: Date): string {
  return dayjs(date).format(STANDARD_DATE_TIME_FORMAT);
}

export function formatToIsoTime(date: Date | undefined | null): string {
  if (date === undefined || date === null) {
    return '';
  }
  return dayjs(date).toISOString();
}

export function formatToIsoDate(date: Date | undefined | null): string {
  if (date === undefined || date === null) {
    return '';
  }
  return dayjs(date).format(SHORT_ISO);
}

export function getDateFromString(dateString?: string | null, defaultDate?: Date): Date {
  if (dateString !== undefined && dateString !== null) {
    const parsedDate = dayjs(dateString);
    if (parsedDate.isValid()) {
      return parsedDate.toDate();
    }
  }
  if (defaultDate !== undefined) {
    return defaultDate;
  }

  return new Date();
}
