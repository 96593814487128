import React from 'react';
import { NavButton } from '../../navigation/NavButton';
import { EntityDefinition } from '../entityTypes';
import { getNameDashPlural } from '../entityHelper';

interface Props {
  label?: string;
  entityDefinition?: EntityDefinition;
  entityId?: string;
}

export function ViewEntityButton(props: Props) {
  const { label = 'Back', entityDefinition, entityId } = props;

  if (!entityId || !entityDefinition) {
    return null;
  }

  return <NavButton label={label} location={`/${getNameDashPlural(entityDefinition.name)}/${entityId}`} />;
}
