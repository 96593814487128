import { EntityDefinition } from './entityTypes';

export interface EntityDefinitionRecord {
  name: string;
  definition: EntityDefinition;
}

const entityDefinitions: EntityDefinitionRecord[] = [];

export function registerEntityDefinition(name: string, definition: EntityDefinition) {
  entityDefinitions.push({
    name,
    definition
  });
}

export function getEntityDefinition(name: string): EntityDefinition {
  const found = entityDefinitions.find((r) => r.name === name);
  if (!found) {
    throw new Error(`Unable to find entity definition with name ${name}`);
  }
  return found.definition;
}
