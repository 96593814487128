import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SiteTitleHeader from './SiteTitleHeader';
import { Container } from '@material-ui/core';
import { TabDefinition } from './TabDefinition';

export interface Props {
  title: string;
  children: React.ReactNode;
  tabDefinitions?: TabDefinition[];
}

const useStyles = makeStyles((theme) => ({
  pageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  mainContent: {
    flex: 1,
    padding: '32px 16px',
    background: '#eaeff1',
    [theme.breakpoints.up('sm')]: {
      padding: '48px 24px'
    }
  }
}));

function SitePage(props: Props) {
  const { title, children, tabDefinitions } = props;
  const classes = useStyles();

  return (
    <div className={classes.pageContent}>
      <SiteTitleHeader title={title} tabDefinitions={tabDefinitions} />
      <main className={classes.mainContent}>
        <Container>{children}</Container>
      </main>
    </div>
  );
}

export default SitePage;
