import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const meetDefinition: EntityDefinition = createEntityDefinition({
  name: 'meet',
  label: 'Meet',
  pages: {
    list: {
      orderBy: 'date_DESC'
    },
    view: {
      childLists: [
        {
          name: 'races',
          childField: 'meet',
          title: 'Races',
          type: 'race',
          filterBy: [{ childFilter: 'meetId_equal', entityField: 'id' }],
          orderBy: 'date_ASC',
          overridePageSize: 12,
          canCreate: true,
          overrideFields: ['name', 'date', 'groupLevel'],
          createFields: ['date', 'track']
        }
      ]
    },
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      tableOptions: {
        visibleByDefault: true,
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'date',
      label: 'Date',
      type: 'date',
      tableOptions: {
        visibleByDefault: true,
        sortable: true,
        canFilter: true
      }
    },
    {
      name: 'track',
      label: 'Track',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'track',
          id: 'id',
          value: 'name',
          parentField: 'trackId'
        },
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('meet', meetDefinition);
