import { Avatar, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { USER_PROFILE_QUERY } from './UserQueries';
import { logout } from '../auth/authHelper';

const useStyles = makeStyles((theme) => ({
  iconButtonAvatar: {
    padding: 4
  }
}));

export function MyProfileButton() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const { loading, error, data: queryData } = useQuery(USER_PROFILE_QUERY);
  const goToPage = async (location: string) => {
    setAnchorEl(null);
    history.push(location);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (error) {
    return null;
  }

  const user = queryData?.viewer.user;
  if (loading) {
    return (
      <Skeleton>
        <IconButton />
      </Skeleton>
    );
  }

  return (
    <>
      <IconButton
        data-id="icon-button-user-menu"
        aria-describedby="user-options"
        size="small"
        aria-controls="user-options"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        className={classes.iconButtonAvatar}
      >
        <Avatar src={user?.imageUrl} />
      </IconButton>
      <Button
        data-id="button-user-menu"
        aria-describedby="user-options"
        color="inherit"
        aria-controls="user-options"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {user?.name}
      </Button>
      <Menu
        id="user-options"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem data-id="button-nav-my-profile" onClick={() => goToPage('/my-profile')}>
          Profile
        </MenuItem>
        <MenuItem data-id="button-logout" onClick={() => logout()}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
