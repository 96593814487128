import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { BasicForm } from '../../form/BasicForm';
import { FormFieldDefinition } from '../../form/FormFieldDefinition';
import { DataZone } from '../../zones/DataZone';

interface Props {
  onFormSubmit: (result: any) => void;
  onFormCancel: () => void;
  meets: any[];
}

export const FAST_TRACK_RACE_IMPORT_MUTATION = gql`
  mutation CreateFastTrackRaces($input: FastTrackRacesCreateInput!) {
    createFastTrackRaces(input: $input) {
      races {
        id
        fastTrackMeetId
        meetPosition
        date
        trackName
        distance
        raceGrade
        raceName
        raceNum
        raceTime
        createdAt
        updatedAt
      }
    }
  }
`;

function getDuplicateNames(meets: any[]): string[] {
  const names = meets.map((meet) => meet.name);
  const sortedNames = names.sort();
  let results = [];
  for (let i = 0; i < sortedNames.length - 1; i++) {
    if (sortedNames[i + 1] === sortedNames[i]) {
      results.push(sortedNames[i]);
    }
  }
  return results;
}

export function RaceImportMeetSelectorForm(props: Props) {
  const { onFormSubmit, onFormCancel, meets } = props;
  const [importFastTrackRaces, { loading: mutationLoading, error: mutationError }] = useMutation(
    FAST_TRACK_RACE_IMPORT_MUTATION
  );

  if (meets.length === 0) {
    return <div>No meets found for this date</div>;
  }

  const duplicateNames = getDuplicateNames(meets);

  const processedMeets = meets.map((meet) => {
    const name = meet.name;
    if (duplicateNames.includes(name)) {
      return {
        id: meet.id,
        name: `${name} (${meet.timeslot})`,
      };
    } else {
      return {
        id: meet.id,
        name
      };
    }
  });

  const meetingFields: FormFieldDefinition[] = [
    {
      name: 'track',
      label: '',
      type: 'radioList',
      mandatory: true,
      enabled: true,
      typeOptions: {
        radioList: {
          options: processedMeets
        }
      }
    }
  ];

  const onMeetSelect = async (formData: any) => {
    const fastTrackMeetId = formData.track;
    const fastTrackRaces = await importFastTrackRaces({ variables: { input: { fastTrackMeetId } } });
    onFormSubmit(fastTrackRaces);
  };

  return (
    <DataZone loading={mutationLoading} error={mutationError} loadingType="spinner" errorType="errorMessage">
      <BasicForm
        fields={meetingFields}
        submitLabel="Next"
        onFormSubmit={onMeetSelect}
        cancelLabel="Back"
        onFormCancel={onFormCancel}
      />
    </DataZone>
  );
}
