import { isArray, isString, isEmpty } from 'lodash';
import { sentenceCase } from 'change-case';

export function arrayToString(fields: Array<string | null | undefined> = [], separator: string = ' '): string {
  if (!isArray(fields)) {
    return '';
  }
  return fields
    .filter((x) => isString(x))
    .map((field) => field && field.trim())
    .filter((x) => !isEmpty(x))
    .join(separator)
    .trim();
}

export interface NumberToStringOptions {
  defaultTo: 'zero' | 'empty';
}

export function numberToString(value: number | undefined | null, options?: NumberToStringOptions): string {
  const defaultTo = options?.defaultTo ?? 'empty';
  if (value === undefined || value === null) {
    return defaultTo === 'zero' ? '0' : '';
  }
  return value.toString(10);
}

export function formatString(value: string | undefined | null): string {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
}

export function booleanToString(value: boolean | undefined | null): string {
  return value === true ? 'Yes' : 'No';
}

export function valueToSentenceCase(value: string | undefined | null): string {
  if (value === undefined || value === null) {
    return '';
  }
  return sentenceCase(value);
}
