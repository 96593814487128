import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { importReferenceDefinition } from './importRef/ImportRefDefinition';
import RankingList from './rankings/RankingList';
import SiteLayout from '../site/SiteLayout';
import SettingsPage from '../settings/SettingsPage';
import MyProfilePage from '../user/MyProfilePage';
import { gql, useQuery } from '@apollo/client';
import { ViewerLoadQuery } from '../graphql-types/ViewerLoadQuery';
import { USER_PROFILE_FRAGMENT } from '../user/UserQueries';
import { StakeEarnersListPage } from './rankings/StakeEarnersListPage';
import { TrackListPage } from './track/TrackListPage';
import { GroupRaceResultsPage } from './rankings/GroupRaceResultsPage';
import { HonourRollsPage } from './rankings/HonourRollsPage';
import { personDefinition } from './person/personDefinition';
import { EntityViewPage } from '../entity/pages/EntityViewPage';
import { EntityListPage } from '../entity/pages/EntityListPage';
import { EntityEditPage } from '../entity/pages/EntityEditPage';
import { EntityCreatePage } from '../entity/pages/EntityCreatePage';
import { useAppContext } from './AppContext';
import { greyhoundDefinition } from './greyhound/greyhoundDefinition';
import { fastTrackDogDefinition } from './fastTrackDog/fastTrackDogDefinition';
import { userDefinition } from '../user/userDefinition';
import { trackDefinition } from './track/trackDefinition';
import { featureGroupDefinition } from './featureGroup/featureGroupDefinition';
import { raceDefinition } from './race/raceDefinition';
import { clubDefinition } from './club/clubDefinition';
import { raceFieldDefinition } from './race/raceFieldDefinition';
import { scoreDefinition } from './score/scoreDefinition';
import { externalSystemDefinition } from '../externalReference/externalSystemDefinition';
import { externalReferenceDefinition } from '../externalReference/externalReferenceDefinition';
import { fastTrackMeetDefinition } from './fastTrackMeet/fastTrackMeetDefinition';
import { fastTrackTrackDefinition } from './fastTrackTrack/fastTrackTrackDefinition';
import { meetDefinition } from './meet/meetDefinition';
import { TrainerRankingList } from './trainerRankings/TrainerRankingList';
import { fastTrackRaceDefinition } from './fastTrackRace/fastTrackRaceDefinition';
import { RaceImportPage } from './race/RaceImportPage';
import { fastTrackRaceDogDefinition } from './fastTrackRace/fastTrackRaceDogDefinition';

const VIEWER_LOAD_QUERY = gql`
  query ViewerLoadQuery {
    viewer {
      user {
        ...UserProfile
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export default function Ranker() {
  const { data } = useQuery<ViewerLoadQuery>(VIEWER_LOAD_QUERY);
  const user = data?.viewer.user;
  const { setContext } = useAppContext();
  useEffect(() => {
    const isAuthenticated = user != null;
    setContext({ user, isAuthenticated });
    // eslint-disable-next-line
  }, [user]);

  return (
    <SiteLayout>
      <Switch>
        <Route path="/" exact>
          <Redirect to={{ pathname: '/rankings/greyhound' }} />
        </Route>
        <Route path="/rankings" exact>
          <Redirect to={{ pathname: '/rankings/greyhound' }} />
        </Route>
        <Route path="/rankings/greyhound">
          <RankingList title={'Rankings'} system={'GREYHOUND'} />
        </Route>
        <Route path="/rankings/sire">
          <RankingList title={'Rankings'} system={'SIRE'} />
        </Route>
        <Route path="/rankings/dam">
          <RankingList title={'Rankings'} system={'DAM'} />
        </Route>
        <Route path="/rankings/trainer">
          <TrainerRankingList />
        </Route>
        <Route path="/earning-rankings">
          <StakeEarnersListPage />
        </Route>
        <Route path="/track-records">
          <TrackListPage />
        </Route>
        <Route path="/group-race-results">
          <GroupRaceResultsPage />
        </Route>
        <Route path="/honour-rolls">
          <HonourRollsPage />
        </Route>

        <Route path="/persons/create">
          <EntityCreatePage entityDefinition={personDefinition} />
        </Route>
        <Route path="/persons/:id/edit">
          <EntityEditPage entityDefinition={personDefinition} />
        </Route>
        <Route path="/persons/:id">
          <EntityViewPage entityDefinition={personDefinition} />
        </Route>
        <Route path="/persons">
          <EntityListPage entityDefinition={personDefinition} />
        </Route>

        <Route path="/fast-track-dogs/create">
          <EntityCreatePage entityDefinition={fastTrackDogDefinition} />
        </Route>
        <Route path="/fast-track-dogs/:id/edit">
          <EntityEditPage entityDefinition={fastTrackDogDefinition} />
        </Route>
        <Route path="/fast-track-dogs/:id">
          <EntityViewPage entityDefinition={fastTrackDogDefinition} />
        </Route>
        <Route path="/fast-track-dogs">
          <EntityListPage entityDefinition={fastTrackDogDefinition} />
        </Route>

        <Route path="/clubs/create">
          <EntityCreatePage entityDefinition={clubDefinition} />
        </Route>
        <Route path="/clubs/:id/edit">
          <EntityEditPage entityDefinition={clubDefinition} />
        </Route>
        <Route path="/clubs/:id">
          <EntityViewPage entityDefinition={clubDefinition} />
        </Route>
        <Route path="/clubs">
          <EntityListPage entityDefinition={clubDefinition} />
        </Route>

        <Route path="/greyhounds/create">
          <EntityCreatePage entityDefinition={greyhoundDefinition} />
        </Route>
        <Route path="/greyhounds/:id/edit">
          <EntityEditPage entityDefinition={greyhoundDefinition} />
        </Route>
        <Route path="/greyhounds/:id">
          <EntityViewPage entityDefinition={greyhoundDefinition} />
        </Route>
        <Route path="/greyhounds">
          <EntityListPage entityDefinition={greyhoundDefinition} />
        </Route>

        <Route path="/tracks/create">
          <EntityCreatePage entityDefinition={trackDefinition} />
        </Route>
        <Route path="/tracks/:id/edit">
          <EntityEditPage entityDefinition={trackDefinition} />
        </Route>
        <Route path="/tracks/:id">
          <EntityViewPage entityDefinition={trackDefinition} />
        </Route>
        <Route path="/tracks">
          <EntityListPage entityDefinition={trackDefinition} />
        </Route>

        <Route path="/feature-groups/create">
          <EntityCreatePage entityDefinition={featureGroupDefinition} />
        </Route>
        <Route path="/feature-groups/:id/edit">
          <EntityEditPage entityDefinition={featureGroupDefinition} />
        </Route>
        <Route path="/feature-groups/:id">
          <EntityViewPage entityDefinition={featureGroupDefinition} />
        </Route>
        <Route path="/feature-groups">
          <EntityListPage entityDefinition={featureGroupDefinition} />
        </Route>

        <Route path="/races/create">
          <EntityCreatePage entityDefinition={raceDefinition} />
        </Route>
        <Route path="/races/:id/edit">
          <EntityEditPage entityDefinition={raceDefinition} />
        </Route>
        <Route path="/races/:id">
          <EntityViewPage entityDefinition={raceDefinition} />
        </Route>
        <Route path="/races">
          <EntityListPage entityDefinition={raceDefinition} />
        </Route>

        <Route path="/race-fields/create">
          <EntityCreatePage entityDefinition={raceFieldDefinition} />
        </Route>
        <Route path="/race-fields/:id/edit">
          <EntityEditPage entityDefinition={raceFieldDefinition} />
        </Route>
        <Route path="/race-fields/:id">
          <EntityViewPage entityDefinition={raceFieldDefinition} />
        </Route>
        <Route path="/race-fields">
          <EntityListPage entityDefinition={raceFieldDefinition} />
        </Route>

        <Route path="/scores/:id">
          <EntityViewPage entityDefinition={scoreDefinition} />
        </Route>
        <Route path="/scores">
          <EntityListPage entityDefinition={scoreDefinition} />
        </Route>

        <Route path="/meets/create">
          <EntityCreatePage entityDefinition={meetDefinition} />
        </Route>
        <Route path="/meets/:id/edit">
          <EntityEditPage entityDefinition={meetDefinition} />
        </Route>
        <Route path="/meets/:id">
          <EntityViewPage entityDefinition={meetDefinition} />
        </Route>
        <Route path="/meets">
          <EntityListPage entityDefinition={meetDefinition} />
        </Route>

        <Route path="/external-systems/create">
          <EntityCreatePage entityDefinition={externalSystemDefinition} />
        </Route>
        <Route path="/external-systems/:id/edit">
          <EntityEditPage entityDefinition={externalSystemDefinition} />
        </Route>
        <Route path="/external-systems/:id">
          <EntityViewPage entityDefinition={externalSystemDefinition} />
        </Route>
        <Route path="/external-systems">
          <EntityListPage entityDefinition={externalSystemDefinition} />
        </Route>

        <Route path="/external-references/:id">
          <EntityViewPage entityDefinition={externalReferenceDefinition} />
        </Route>
        <Route path="/external-references">
          <EntityListPage entityDefinition={externalReferenceDefinition} />
        </Route>

        <Route path="/fast-track-meets/:id">
          <EntityViewPage entityDefinition={fastTrackMeetDefinition} />
        </Route>
        <Route path="/fast-track-meets">
          <EntityListPage entityDefinition={fastTrackMeetDefinition} />
        </Route>

        <Route path="/fast-track-tracks/create">
          <EntityCreatePage entityDefinition={fastTrackTrackDefinition} />
        </Route>
        <Route path="/fast-track-tracks/:id/edit">
          <EntityEditPage entityDefinition={fastTrackTrackDefinition} />
        </Route>
        <Route path="/fast-track-tracks/:id">
          <EntityViewPage entityDefinition={fastTrackTrackDefinition} />
        </Route>
        <Route path="/fast-track-tracks">
          <EntityListPage entityDefinition={fastTrackTrackDefinition} />
        </Route>

        <Route path="/fast-track-races/:id">
          <EntityViewPage entityDefinition={fastTrackRaceDefinition} />
        </Route>
        <Route path="/fast-track-races">
          <EntityListPage entityDefinition={fastTrackRaceDefinition} />
        </Route>

        <Route path="/fast-track-race-dogs/:id">
          <EntityViewPage entityDefinition={fastTrackRaceDogDefinition} />
        </Route>
        <Route path="/fast-track-race-dogs">
          <EntityListPage entityDefinition={fastTrackRaceDogDefinition} />
        </Route>

        <Route path="/race-import">
          <RaceImportPage />
        </Route>

        <Route path="/import-references/:id">
          <EntityViewPage entityDefinition={importReferenceDefinition} singleColumn={true} />
        </Route>
        <Route path="/import-references">
          <EntityListPage entityDefinition={importReferenceDefinition} />
        </Route>

        <Route path="/users/:id/edit">
          <EntityEditPage entityDefinition={userDefinition} />
        </Route>
        <Route path="/users/:id">
          <EntityViewPage entityDefinition={userDefinition} />
        </Route>
        <Route path="/users">
          <EntityListPage entityDefinition={userDefinition} />
        </Route>
        <Route path="/settings">
          <SettingsPage />
        </Route>
        <Route path="/my-profile">
          <MyProfilePage />
        </Route>
      </Switch>
    </SiteLayout>
  );
}
