import React from 'react';
import { useHistory } from 'react-router-dom';
import { doesRoleHaveAccess, useRole } from '../../zones/zoneHelper';
import { getEntityDefinition } from '../entityDefinitionService';
import { getFieldIdForEntityField, getNameDashPlural } from '../entityHelper';
import { Link } from '@material-ui/core';
import { FieldDefinition } from '../entityTypes';
import { EntityViewField } from './EntityViewField';
import { EntityViewProps } from './EntityViewProps';

export function EntityViewEntityField(props: EntityViewProps) {
  const { value, fieldDefinition } = props;
  const role = useRole();
  const history = useHistory();
  const fieldTypeOptions = fieldDefinition.typeOptions.entity;
  if (!fieldTypeOptions) {
    return null;
  }
  const shouldLink = fieldTypeOptions.linkTo ?? true;
  const fieldName = fieldTypeOptions.value;
  const subFieldDefinition: FieldDefinition = {
    name: fieldName,
    label: fieldName,
    type: fieldTypeOptions.valueType ?? 'string',
    fieldArgs: [],
    typeOptions: {},
    tableOptions: {
      enabled: false,
      sortable: false,
      canFilter: false,
      visibleByDefault: false
    },
    viewOptions: {
      selfLink: false,
      enabled: false
    },
    editOptions: {
      enabled: false,
      mandatory: false
    }
  };
  const entityType = fieldTypeOptions?.type ?? '';
  const targetEntityDefinition = getEntityDefinition(entityType);
  const hasAccess = doesRoleHaveAccess(targetEntityDefinition.pages.view.enabledForRole, role);
  const id = getFieldIdForEntityField(value, fieldDefinition);
  const isLink = targetEntityDefinition.pages.view.enabled && hasAccess && shouldLink;

  if (!isLink) {
    return (
      <EntityViewField fieldDefinition={subFieldDefinition} entityDefinition={targetEntityDefinition} entity={value} />
    );
  }

  return (
    <Link component="button" underline="always" onClick={() => history.push(`/${getNameDashPlural(entityType)}/${id}`)}>
      <EntityViewField fieldDefinition={subFieldDefinition} entityDefinition={targetEntityDefinition} entity={value} />
    </Link>
  );
}
