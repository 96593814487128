import React from 'react';
import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { DataZone } from '../zones/DataZone';
import { AppInfoQuery } from '../graphql-types/AppInfoQuery';

export const APP_INFO_QUERY = gql`
  query AppInfoQuery {
    applicationConfig {
      name
      version
    }
  }
`;

export default function AppInfoCard() {
  const { loading, error, data: queryData } = useQuery<AppInfoQuery>(APP_INFO_QUERY);
  const applicationConfig = queryData?.applicationConfig;
  return (
    <DataZone error={error}>
      <Card>
        <CardHeader title="Application Info" />
        <Divider />
        <DataZone loading={loading}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{applicationConfig?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Version</TableCell>
                <TableCell>{applicationConfig?.version}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DataZone>
      </Card>
    </DataZone>
  );
}
