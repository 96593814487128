import React from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import { USER_PROFILE_QUERY } from './UserQueries';
import { DataZone } from '../zones/DataZone';
import { DeleteEntityButton } from '../entity/actions/DeleteEntityButton';
import { userDefinition } from './userDefinition';
import { logout } from '../auth/authHelper';

export const USER_DELETE_MUTATION = gql`
  mutation UserDelete($input: UserDeleteInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 100,
    height: 100
  },
  avatarCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatarText: {
    marginTop: theme.spacing(1)
  }
}));

export default function MyProfileCard() {
  const classes = useStyles();
  const { loading, error, data: queryData } = useQuery(USER_PROFILE_QUERY);
  const user = queryData?.viewer.user;

  const onDelete = async () => {
    await logout();
  };

  return (
    <DataZone loading={loading} error={error}>
      <Card>
        <CardContent className={classes.avatarCard}>
          <Grid container direction={'column'} alignItems={'center'} spacing={2}>
            <Grid item>
              <Avatar alt="user_profile_picture" src={user?.imageUrl} className={classes.avatar} />
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom className={classes.avatarText}>
                {user?.name}
              </Typography>
            </Grid>
            <Grid item>
              <DeleteEntityButton
                label={'Close account'}
                title={'Close account'}
                entityId={user?.id}
                entityDefinition={userDefinition}
                mutation={USER_DELETE_MUTATION}
                navTarget={'/'}
                message={'This will permanently delete your account. Are you sure?'}
                onDelete={onDelete}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DataZone>
  );
}
