import React from 'react';
import { HiddenZone } from './HiddenZone';

interface Props {
  children?: React.ReactNode;
}

export function FeatureZone(props: Props) {
  const { children } = props;
  const isDev = process.env.NODE_ENV !== 'production';
  return <HiddenZone show={isDev}>{children}</HiddenZone>;
}
