import React from 'react';
import SitePage from '../site/SitePage';
import { Grid } from '@material-ui/core';
import AppInfoCard from './AppInfoCard';

interface Props {}

export default function SettingsPage(props: Props) {
  return (
    <SitePage title="Settings">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AppInfoCard />
        </Grid>
      </Grid>
    </SitePage>
  );
}
