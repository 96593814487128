import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const personDefinition: EntityDefinition = createEntityDefinition({
  name: 'person',
  label: 'Owners and Trainers',
  pages: {
    view: {
      childLists: [
        {
          name: 'owned',
          childField: 'owner',
          title: 'Owned Greyhounds',
          type: 'greyhound',
          filterBy: [{ childFilter: 'ownerId_equal', entityField: 'id' }],
          overrideFields: ['name', 'career', 'totalPrizeMoney']
        },
        {
          name: 'trained',
          childField: 'trainer',
          title: 'Trained Greyhounds',
          type: 'greyhound',
          filterBy: [{ childFilter: 'trainerId_equal', entityField: 'id' }],
          overrideFields: ['name', 'career', 'totalPrizeMoney']
        },
        {
          name: 'bred',
          childField: 'breeder',
          title: 'Reared Greyhounds',
          type: 'greyhound',
          filterBy: [{ childFilter: 'breederId_equal', entityField: 'id' }],
          overrideFields: ['name', 'dateOfBirth', 'color']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      },
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'syndicate',
      label: 'Syndicate',
      type: 'boolean',
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('person', personDefinition);
