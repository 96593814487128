import React from 'react';
import { ChildFilterBy, ChildFilters, ChildListDefinition, EntityDefinition } from './entityTypes';
import { buildListQuery } from './pages/entityQueryBuilder';
import { SiteTable } from '../table/SiteTable';
import { getEntityDefinition } from './entityDefinitionService';
import { getNameDashPlural, getNameTitle } from './entityHelper';

export interface Props {
  entityDefinition: EntityDefinition;
  entity: any;
  childListDefinition: ChildListDefinition;
}

export function getChildFiltersForDefinition(entity: any, childListDefinition: ChildListDefinition) {
  const filterBy = childListDefinition.filterBy;
  if ((filterBy as any).length !== undefined) {
    return getChildFilters(entity, filterBy as ChildFilterBy[]);
  } else {
    const childFilterObject = filterBy as ChildFilters;
    const value: { AND?: object; OR?: object } = {};
    if (childFilterObject.AND && childFilterObject.AND.length > 0) {
      value.AND = getChildFilters(entity, childFilterObject.AND);
    }
    if (childFilterObject.OR && childFilterObject.OR.length > 0) {
      value.OR = getChildFilters(entity, childFilterObject.OR);
    }
    return value;
  }
}

export function getChildFilters(entity: any, childFilters: ChildFilterBy[]) {
  const filterList = childFilters.map((filterBy: any) => getChildFilter(entity, filterBy));
  return filterList.reduce((acc, v) => ({ ...acc, ...v }), {});
}

export function getChildFilter(entity: any, childFilter: ChildFilterBy) {
  return { [childFilter.childFilter]: entity[childFilter.entityField] };
}

export function getParentCreateParams(entity: any) {
  return JSON.stringify({
    id: entity.id,
    name: entity.name
  });
}

export function EntityChildListCard(props: Props) {
  const { entity, childListDefinition, entityDefinition } = props;
  const nameTitle = getNameTitle(entityDefinition.name);
  const entityUrlName = getNameDashPlural(entityDefinition.name);
  const childDefinition = getEntityDefinition(childListDefinition.type);
  const listQuery = buildListQuery(childDefinition, `For${nameTitle}`);
  const filterBy = getChildFiltersForDefinition(entity, childListDefinition);
  const orderByOverride = childListDefinition.orderBy;
  const overridePageSize = childListDefinition.overridePageSize ?? 5;
  const returnTo = `/${entityUrlName}/${entity.id}`;
  const createParams = {
    [childListDefinition.childField]: getParentCreateParams(entity),
    returnTo
  };

  const createFields = childListDefinition.createFields;
  if (createFields && createFields.length > 0) {
    createFields.forEach((createField) => {
      const fieldDef = entityDefinition.fields.find((field) => field.name === createField);
      if (fieldDef) {
        if (fieldDef.type === 'entity') {
          createParams[createField] = JSON.stringify({
            id: entity[createField].id,
            name: entity[createField].name
          });
        } else {
          createParams[createField] = entity[createField];
        }
      }
    });
  }

  const standardTableActions = {
    enableSearch: false,
    enableCalendarFilter: false,
    enableExport: false,
    enableColumnEdit: false,
    enableRefresh: false,
    enableCreate: childListDefinition?.canCreate ?? false
  };
  return (
    <SiteTable
      key={`${entityDefinition.name}-${childListDefinition.name}`}
      entityDefinition={childDefinition}
      query={listQuery}
      defaultRowPerPage={overridePageSize}
      rowsPerPageOptions={[overridePageSize]}
      standardTableActions={standardTableActions}
      title={childListDefinition.title}
      columnVisibilityOverrides={childListDefinition.overrideFields}
      filterOverrides={filterBy}
      onEmpty="none"
      createParams={createParams}
      orderByOverride={orderByOverride}
    />
  );
}
