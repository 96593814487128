import { Tab } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab/Tab';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props extends TabProps {
  route: string;
}

export function NavTab(props: Props) {
  const history = useHistory();
  const { route, ...rest } = props;

  const onClick = () => {
    history.push(route);
  };

  return <Tab onClick={onClick} {...rest} />;
}
