import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { ColumnDirection, ColumnVisibility } from './tableTypes';
import { makeStyles } from '@material-ui/core/styles';
import { filterInvisibleColumns } from './tableService';
import { EntityDefinition, FieldDefinition } from '../entity/entityTypes';
import { defaultTableAlignment } from '../entity/entityHelper';

interface Props {
  entityDefinition: EntityDefinition;
  columnVisibilities: Array<ColumnVisibility>;
  onRequestSort: (event: React.MouseEvent<HTMLButtonElement>, columnId: string) => void;
  orderByColumnId: string;
  orderDirection?: ColumnDirection;
}

const useStyles = makeStyles((theme) => ({
  header: {},
  cell: {
    padding: '8px 16px'
  }
}));

export function SiteTableHeader(props: Props) {
  const { entityDefinition, orderByColumnId, orderDirection, onRequestSort, columnVisibilities } = props;
  const classes = useStyles();
  const columns = entityDefinition.fields;
  const showColumns = filterInvisibleColumns(columns, columnVisibilities);
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {showColumns.map((column: FieldDefinition) => {
          const alignment = defaultTableAlignment(column);
          return (
            <TableCell
              className={classes.cell}
              key={column.name}
              align={alignment}
              sortDirection={orderByColumnId === column.name ? orderDirection : false}
            >
              {column.tableOptions.sortable ? (
                <TableSortLabel
                  active={orderByColumnId === column.name}
                  direction={orderDirection}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => onRequestSort(event, column.name)}
                >
                  {column.label}
                </TableSortLabel>
              ) : (
                column.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
