import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { EntityDefinition, FieldDefinition } from '../entityTypes';
import { EntityViewStringField } from './EntityViewStringField';
import { EntityViewBooleanField } from './EntityViewBooleanField';
import { getFieldForFieldDefinition, getNameDashPlural } from '../entityHelper';
import { EntityViewDateField } from './EntityViewDateField';
import { EntityViewEnumField } from './EntityViewEnumField';
import { EntityViewCurrencyField } from './EntityViewCurrencyField';
import { EntityViewIntegerField } from './EntityViewIntegerField';
import { EntityViewEntityField } from './EntityViewEntityField';
import { EntityViewCodeField } from './EntityViewCodeField';
import { Link } from '@material-ui/core';
import { EntityViewFloatField } from './EntityViewFloatField';
import { doesRoleHaveAccess, useRole } from '../../zones/zoneHelper';

interface Props {
  fieldDefinition: FieldDefinition;
  entityDefinition: EntityDefinition;
  entity: any;
}

export function selectViewComponentForType(fieldDefinition: FieldDefinition) {
  if (fieldDefinition.viewOptions.component !== undefined) {
    return fieldDefinition.viewOptions.component;
  }
  switch (fieldDefinition.type) {
    case 'string':
      return EntityViewStringField;
    case 'date':
      return EntityViewDateField;
    case 'enum':
      return EntityViewEnumField;
    case 'currency':
      return EntityViewCurrencyField;
    case 'boolean':
      return EntityViewBooleanField;
    case 'integer':
      return EntityViewIntegerField;
    case 'float':
      return EntityViewFloatField;
    case 'code':
      return EntityViewCodeField;
    case 'entity':
      return EntityViewEntityField;
    case 'custom':
      return fieldDefinition.typeOptions.custom?.component ?? Fragment;
  }
}

export function EntityViewField(props: Props) {
  const { fieldDefinition, entityDefinition, entity } = props;
  const role = useRole();
  const history = useHistory();
  const hasAccess = doesRoleHaveAccess(entityDefinition.pages.view.enabledForRole, role);
  const value = getFieldForFieldDefinition(fieldDefinition, entity);
  const FieldComp = selectViewComponentForType(fieldDefinition);
  if (fieldDefinition.viewOptions.selfLink && hasAccess) {
    return (
      <Link
        component="button"
        underline="always"
        onClick={() => history.push(`/${getNameDashPlural(entityDefinition.name)}/${entity.id}`)}
      >
        <FieldComp fieldDefinition={fieldDefinition} entity={entity} value={value} />
      </Link>
    );
  }

  return <FieldComp fieldDefinition={fieldDefinition} entity={entity} value={value} />;
}
