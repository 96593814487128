import React, { useState } from 'react';
import { DocumentNode, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { DeleteButton } from '../DeleteButton';
import { EntityDefinition } from '../entityTypes';
import { getNameDashPlural } from '../entityHelper';

interface Props {
  label?: string;
  entityId?: string;
  entityDefinition: EntityDefinition;
  mutation: DocumentNode;
  navTarget?: string;
  title?: string;
  message?: string;
  onDelete?: () => void;
}

export function DeleteEntityButton(props: Props) {
  const history = useHistory();
  const { label = 'Delete', entityId, mutation, entityDefinition, navTarget, title, message, onDelete } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [deleteAction, { loading: mutationLoading }] = useMutation(mutation);

  const deleteEntity = async () => {
    try {
      await deleteAction({ variables: { input: { id: entityId } } });
      if (onDelete) {
        onDelete();
      }
      const target = navTarget ? navTarget : `/${getNameDashPlural(entityDefinition.name)}`;
      history.push(target);
    } catch (error) {
      console.error('mutation error', error);
      setErrorMessage(error.message);
    }
  };

  const clearError = () => {
    setErrorMessage(undefined);
  };

  if (!entityId) {
    return null;
  }

  return (
    <DeleteButton
      label={label}
      title={title ? title : `Delete ${entityDefinition.label}`}
      loading={mutationLoading}
      errorMessage={errorMessage}
      message={
        message
          ? message
          : `This will permanently delete this ${entityDefinition.label} are you sure you wish to do this?`
      }
      onDelete={() => deleteEntity()}
      onClose={clearError}
    />
  );
}
