import React from 'react';
import { FieldDefinition } from '../entityTypes';
import { DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import { STANDARD_DATE_FORMAT } from '../../util/dateService';
import dayjs from 'dayjs';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
  formControl: any;
}

export function EntityEditDateField(props: Props) {
  const { fieldDefinition, entity, formErrors, formControl } = props;
  const fieldValue = entity[fieldDefinition.name];
  const defaultValue = fieldValue ? dayjs(fieldValue) : null;
  return (
    <Controller
      control={formControl}
      name={fieldDefinition.name}
      defaultValue={defaultValue}
      render={(controllerProps) => (
        <DatePicker
          {...controllerProps}
          fullWidth
          required={fieldDefinition.editOptions.mandatory}
          label={fieldDefinition.label}
          inputVariant="outlined"
          clearable
          format={STANDARD_DATE_FORMAT}
          error={formErrors[fieldDefinition.name] != null}
          helperText={formErrors[fieldDefinition.name]?.message}
          onChange={(date) => {
            if (date === null || date === undefined) {
              controllerProps.onChange(null);
            } else {
              controllerProps.onChange(date?.toISOString());
            }
          }}
        />
      )}
    />
  );
}
