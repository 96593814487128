import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { getEnumItemsInSentenceCase } from '../../entity/entityEnumHelper';
import { RaceFromFastTrackButton } from '../fastTrackDog/RaceFromFastTrackButton';
import { RaceImportButton } from './RaceImportButton';

export const GROUP_LEVELS = ['GROUP_ONE', 'GROUP_TWO', 'GROUP_THREE', 'LISTED', 'UNGROUPED'];

export const raceDefinition: EntityDefinition = createEntityDefinition({
  name: 'race',
  label: 'Race',
  pages: {
    list: {
      actions: [RaceImportButton],
      orderBy: 'date_DESC'
    },
    view: {
      actions: [RaceFromFastTrackButton],
      childLists: [
        {
          name: 'fields',
          childField: 'race',
          title: 'Fields',
          type: 'raceField',
          filterBy: [{ childFilter: 'raceId_equal', entityField: 'id' }],
          orderBy: 'place_ASC',
          overridePageSize: 10,
          canCreate: true,
          overrideFields: ['place', 'greyhound', 'score']
        }
      ]
    },
    create: {
      onSaveNavTo: 'edit'
    },
    edit: {
      childLists: [
        {
          name: 'fields',
          childField: 'race',
          title: 'Fields',
          type: 'raceField',
          filterBy: [{ childFilter: 'raceId_equal', entityField: 'id' }],
          canCreate: true,
          overrideFields: ['place', 'boxNumber', 'greyhound']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      },
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'date',
      label: 'Date',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      }
    },
    {
      name: 'groupLevel',
      label: 'Group Level',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getEnumItemsInSentenceCase(GROUP_LEVELS)
        }
      }
    },
    {
      name: 'distanceMeters',
      label: 'Distance',
      type: 'integer',
      typeOptions: {
        integer: {
          suffix: 'Meters'
        }
      }
    },
    {
      name: 'disqualified',
      label: 'Disqualified',
      type: 'boolean',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'track',
      label: 'Track',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'track',
          id: 'id',
          value: 'name',
          parentField: 'trackId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'meet',
      label: 'Meet',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'meet',
          id: 'id',
          value: 'name',
          parentField: 'meetId',
          canCreate: false
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'club',
      label: 'Club',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'club',
          id: 'id',
          value: 'name',
          parentField: 'clubId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'featureGroup',
      label: 'Feature',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'featureGroup',
          id: 'id',
          value: 'name',
          parentField: 'featureGroupId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('race', raceDefinition);
