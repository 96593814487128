import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { DataZone } from '../zones/DataZone';

interface Props {
  label?: string;
  title?: string;
  loading: boolean;
  errorMessage?: string;
  message?: string;
  onDelete: () => void;
  onClose?: () => void;
  confirmation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

export function DeleteButton(props: Props) {
  const classes = useStyles();
  const {
    label = 'Delete',
    message = 'Are you sure?',
    onDelete,
    confirmation = true,
    loading,
    errorMessage,
    title = 'Delete',
    onClose
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (confirmation) {
      setOpen(true);
    } else {
      onDelete();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        className={classes.button}
        variant="contained"
        startIcon={<DeleteForeverIcon />}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="delete-dialog" aria-describedby="delete dialog">
        <DataZone loading={loading} loadingMessage={'Deleting...'} loadingType="spinner" minWidth={500} minHeight={52}>
          {errorMessage ? null : (
            <>
              <DialogTitle id="delete-dialog">{title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={onDelete} color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </>
          )}
        </DataZone>
        {errorMessage ? (
          <>
            <DialogTitle id="delete-dialog-error">Error</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-error-description">{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Okay
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    </>
  );
}
