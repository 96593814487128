import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { EntityDefinition } from '../../entity/entityTypes';
import AddIcon from '@material-ui/icons/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { buildEntityFields } from '../../entity/pages/entityQueryBuilder';
import { trackDefinition } from '../track/trackDefinition';

interface Props {
  entityDefinition: EntityDefinition;
  entity: object;
}

export function buildQuery() {
  const entityFields = buildEntityFields(trackDefinition);
  return gql(`mutation TrackCreateFromFastTrack($input: TrackCreateFromFastTrackTrackInput!) {
    createTrackFromFastTrack(input: $input) {
      track {
        ${entityFields}
      }
    }
  }`);
}

export function TrackFromFastTrackTrackButton(props: Props) {
  const { entity } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [syncAction, { loading }] = useMutation(buildQuery());
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const entityId = (entity as any).id;
  if (!entityId) {
    return null;
  }
  const handleClickOpen = async () => {
    setErrorMessage(undefined);
    setOpen(true);
    try {
      const { data } = await syncAction({ variables: { input: { id: entityId } } });
      const newEntity = data?.createTrackFromFastTrack?.track;
      if (newEntity) {
        const target = `/tracks/${newEntity.id}`;
        history.push(target);
      }
      setOpen(false);
    } catch (error) {
      console.error('mutation error', error);
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button disabled={open} color="primary" onClick={handleClickOpen} variant="contained" startIcon={<AddIcon />}>
        Create Track
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog" aria-describedby="confirm dialog">
        {errorMessage ? (
          <>
            <DialogTitle id="confirm-dialog-error">Error</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-error-description">{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen} color="primary" autoFocus>
                Retry
              </Button>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <LoadingSpinner minHeight={52} minWidth={500} message="Creating track from fast track record..." />
        )}
      </Dialog>
    </>
  );
}
