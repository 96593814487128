import React from 'react';
import { ColumnVisibility } from './tableTypes';
import IconButton from '@material-ui/core/IconButton';
import ColumnIcon from '@material-ui/icons/ViewColumn';
import ClearIcon from '@material-ui/icons/Clear';
import { Checkbox, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core';

interface Props {
  columnVisibilities: Array<ColumnVisibility>;
  setColumnVisibilities: (columns: Array<ColumnVisibility>) => void;
}

export function SiteTableActionColumnSelector(props: Props) {
  const { columnVisibilities, setColumnVisibilities } = props;
  const [columnMenuAnchorEl, setColumnMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const openColumnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColumnMenuAnchorEl(event.currentTarget);
  };

  const closeColumnMenu = () => {
    setColumnMenuAnchorEl(null);
  };

  const toggleColumn = (columnId: string) => {
    setColumnVisibilities(
      columnVisibilities.map(c => {
        if (c.id === columnId) c.visible = !c.visible;
        return c;
      })
    );
  };

  return (
    <>
      <IconButton
        aria-label="open column menu"
        aria-describedby="column-options"
        aria-controls="column-options"
        aria-haspopup="true"
        onClick={openColumnMenu}
      >
        <ColumnIcon />
      </IconButton>
      <Menu
        id="column-options"
        anchorEl={columnMenuAnchorEl}
        keepMounted
        open={Boolean(columnMenuAnchorEl)}
        onClose={closeColumnMenu}
      >
        <MenuItem component="div" disabled>
          Show columns
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="close column menu" onClick={closeColumnMenu}>
              <ClearIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </MenuItem>
        {columnVisibilities.map(cv => {
          return (
            <MenuItem key={cv.id} dense onClick={() => toggleColumn(cv.id)}>
              <ListItemIcon>
                <Checkbox edge="start" checked={cv.visible} disableRipple />
              </ListItemIcon>
              <ListItemText primary={cv.label} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
