import React from 'react';
import { buildListQuery } from '../../entity/pages/entityQueryBuilder';
import SitePage from '../../site/SitePage';
import { useSiteSettings } from '../../site/SiteSettings';
import { SiteTable } from '../../table/SiteTable';
import { RankingDatePicker } from './RankingDatePicker';
import { RankingCsvExport } from './RankingCsvExport';
import { doesRoleHaveAccess, useRole } from '../../zones/zoneHelper';
import { rankingDefinition } from './rankingDefinition';
import { rankingTabDefinitions } from './RankingTabDefinitions';

export default function RankingList(props: { title: string; system: string }) {
  const { title, system } = props;
  const role = useRole();
  const { settings } = useSiteSettings();
  const hasAdmin = doesRoleHaveAccess('ADMIN', role);
  const listQuery = buildListQuery(rankingDefinition);
  const standardTableActions = {
    enableSearch: false,
    enableCalendarFilter: false,
    enableExport: !hasAdmin,
    enableColumnEdit: false,
    enableRefresh: true,
    enableCreate: false
  };
  const filterBy = {
    startDate: settings.rankingStartDate,
    endDate: settings.rankingEndDate,
    system
  };
  return (
    <SitePage title={title} tabDefinitions={rankingTabDefinitions}>
      <SiteTable
        key={`${rankingDefinition.name}-${system}`}
        entityDefinition={rankingDefinition}
        query={listQuery}
        defaultRowPerPage={100}
        rowsPerPageOptions={[100]}
        standardTableActions={standardTableActions}
        filterOverrides={filterBy}
        customTableActions={[RankingDatePicker, RankingCsvExport]}
        onEmpty="none"
      />
    </SitePage>
  );
}
