import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { FieldDefinition } from '../entity/entityTypes';
import { FormMessage } from './FormMessage';
import { buildValidationSchemaForEntityDefinition, parseFormDataForSubmission } from '../entity/entityHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import { EntityEditField } from '../entity/editFields/EntityEditField';

interface Props {
  formTitle: string;
  fieldDefinitions: FieldDefinition[];
  editQuery: DocumentNode;
  submitLabel: string;
  onFormSubmit: (result: any) => void;
  cancelLabel?: string;
  onFormCancel?: () => void;
}

export function MutationForm(props: Props) {
  const { editQuery, fieldDefinitions, onFormSubmit, onFormCancel, submitLabel, cancelLabel } = props;
  const fields = fieldDefinitions;
  const [updateEntity, { loading: mutationLoading, error: mutationError }] = useMutation(editQuery);
  const validationSchema = buildValidationSchemaForEntityDefinition(fields);
  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const [formMessage, setFormMessage] = useState<string | undefined>(undefined);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined);
  const apolloClient = useApolloClient();

  const onSubmit = async (formData: any) => {
    try {
      const parsedFormData = await parseFormDataForSubmission(apolloClient, fields, formData);
      const result = await updateEntity({ variables: { input: { ...parsedFormData } } });
      onFormSubmit(result);
      setFormMessage('Successful');
    } catch (error) {
      console.error('mutation error', error);
      setFormErrorMessage(error.message);
    }
  };

  const onCancel = async () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  const errorMessage = mutationError?.message ?? formErrorMessage;

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={3}>
        {fields
          .filter((field) => field.editOptions.enabled)
          .map((field) => {
            return (
              <Grid item xs={12} key={field.name}>
                <EntityEditField
                  fieldDefinition={field}
                  entity={{}}
                  formRef={register}
                  formErrors={errors}
                  formControl={control}
                  watch={watch}
                  setValue={setValue}
                />
              </Grid>
            );
          })}
        <FormMessage message={errorMessage} severity="error" />
        <FormMessage message={formMessage} severity="success" />
      </Grid>
      <Grid container spacing={2}>
        {cancelLabel ? (
          <Grid item>
            <Button disabled={mutationLoading} variant="contained" onClick={onCancel}>
              {cancelLabel}
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <Button disabled={mutationLoading} variant="contained" color="primary" type="submit">
            {submitLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
