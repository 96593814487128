import * as yup from 'yup';
import { ObjectSchema, Schema } from 'yup';
import { FormFieldDefinition } from './FormFieldDefinition';

export function buildValidationSchemaForFormFieldDefinition(fieldDefinitions: FormFieldDefinition[]): ObjectSchema {
  const fieldValidations = fieldDefinitions
    .filter((field) => field.enabled)
    .reduce((schema, field) => {
      const validation = getFormFieldValidation(field);
      if (validation) {
        schema[field.name] = validation;
      }
      return schema;
    }, {} as any);
  return yup.object().shape(fieldValidations);
}

export function getFormFieldValidation(fieldDefinition: FormFieldDefinition): Schema<any> | undefined {
  let baseSchema = getBaseFormFieldValidation(fieldDefinition);
  if (!baseSchema) {
    return undefined;
  }
  if (fieldDefinition.mandatory) {
    baseSchema = baseSchema.required();
  } else {
    baseSchema = baseSchema.notRequired();
  }
  return baseSchema;
}

export function getBaseFormFieldValidation(fieldDefinition: FormFieldDefinition): any {
  switch (fieldDefinition.type) {
    case 'radioList':
      return yup.string();
    default:
      return undefined;
  }
}
