import React from 'react';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { PagingOptions } from './tableTypes';
import { makeStyles } from '@material-ui/core/styles';
import { SiteTablePaginationActions } from './SiteTablePagingActions';
import { EntityDefinition } from '../entity/entityTypes';

interface Props {
  entityDefinition: EntityDefinition;
  pagingOptions: PagingOptions;
}

const useStyles = makeStyles(theme => ({
  footerRow: {},
  hideOnXs: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  }
}));

export function SiteTableFooter(props: Props) {
  const { entityDefinition, pagingOptions } = props;
  const classes = useStyles();
  const columns = entityDefinition.fields;
  const { rowsPerPageOptions, total, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = pagingOptions;
  return (
    <TableFooter>
      <TableRow className={classes.footerRow}>
        <TablePagination
          classes={{
            caption: classes.hideOnXs,
            selectRoot: classes.hideOnXs
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={columns.length}
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: true
          }}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          ActionsComponent={SiteTablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
}
