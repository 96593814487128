import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { wrappedFetch } from './apiHelper';

export interface ApolloClientConfig {
  graphqlUrl: string;
  apolloDevToolsEnabled?: boolean;
}

export function createApolloClient(config: ApolloClientConfig): ApolloClient<any> {
  const { graphqlUrl, apolloDevToolsEnabled = false } = config;
  const httpLink = createHttpLink({
    uri: graphqlUrl,
    fetch: wrappedFetch
  });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({ addTypename: true }),
    connectToDevTools: apolloDevToolsEnabled
  });
}
