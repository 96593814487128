import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { formatDataAsCsv } from './tableService';
import { EntityDefinition } from '../entity/entityTypes';
import { ColumnVisibility } from './tableTypes';

interface Props<EntityKind> {
  entityDefinition: EntityDefinition;
  columnVisibilities: Array<ColumnVisibility>;
  rows: Array<EntityKind>;
  maxColumnLength?: number;
  includeColumnOption?: boolean;
}

export function SiteTableExport<EntityKind>(props: Props<EntityKind>) {
  const { entityDefinition, rows, maxColumnLength, columnVisibilities, includeColumnOption = false } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const visibleColumnNames = columnVisibilities.filter((cv) => cv.visible).map((cv) => cv.id);
  const columns = entityDefinition.fields.filter((fd) => visibleColumnNames.includes(fd.name));

  const downloadData = (maxLength?: number) => {
    const csvRows = formatDataAsCsv(columns, rows, maxLength);
    const csvString = Papa.unparse(csvRows);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    const fileName = `${entityDefinition.name}_${dayjs().format('YYYY_MMM_DD_mm')}.csv`;
    FileSaver.saveAs(blob, fileName);
    closeMenu();
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const onIconClick = includeColumnOption ? openMenu : () => downloadData();

  return (
    <>
      <IconButton
        aria-label="open more menu"
        aria-describedby="more-options"
        aria-controls="more-options"
        aria-haspopup="true"
        onClick={onIconClick}
      >
        <SaveAltIcon />
      </IconButton>
      <Menu id="export-options" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
        <MenuItem dense onClick={() => downloadData()}>
          csv
        </MenuItem>
        <MenuItem dense onClick={() => downloadData(maxColumnLength)}>
          csv in columns
        </MenuItem>
      </Menu>
    </>
  );
}
