import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { DocumentNode, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useQueryParams } from '../navigation/queryParamHelper';
import { DataZone } from '../zones/DataZone';
import { useHistory, useParams } from 'react-router-dom';
import { FormMessage } from '../form/FormMessage';
import { ViewEntityButton } from './actions/ViewEntityButton';
import { EntityDefinition } from './entityTypes';
import { buildValidationSchemaForEntityDefinition, entityEditNavTo, parseFormDataForSubmission } from './entityHelper';
import { EntityEditField } from './editFields/EntityEditField';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  entityDefinition: EntityDefinition;
  viewQuery: DocumentNode;
  editQuery: DocumentNode;
}

export function EntityEditCard(props: Props) {
  const { viewQuery, editQuery, entityDefinition } = props;
  const history = useHistory();
  const queryParams = useQueryParams();
  const fields = entityDefinition.fields;
  let { id } = useParams();
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(viewQuery, {
    variables: { id }
  });
  const [updateEntity, { loading: mutationLoading, error: mutationError }] = useMutation(editQuery);
  const validationSchema = buildValidationSchemaForEntityDefinition(entityDefinition.fields);
  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const [formMessage, setFormMessage] = useState<string | undefined>(undefined);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined);
  const apolloClient = useApolloClient();

  const entity = queryData && queryData[entityDefinition.name] ? queryData[entityDefinition.name] : {};
  const onSubmit = async (formData: any) => {
    if (entity?.id) {
      try {
        const parsedFormData = await parseFormDataForSubmission(apolloClient, entityDefinition.fields, formData);
        await updateEntity({ variables: { input: { id: entity.id, ...parsedFormData } } });
        setFormMessage('Save successful');
        const returnTo = queryParams.get('returnTo');
        entityEditNavTo(entityDefinition,returnTo, entity, history);
      } catch (error) {
        console.error('mutation error', error);
        setFormErrorMessage(error.message);
      }
    }
  };

  const errorMessage = mutationError?.message ?? formErrorMessage;

  return (
    <DataZone loading={queryLoading} error={queryError}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Card>
          <CardHeader title={entityDefinition.label} />
          <CardContent>
            <Grid container spacing={3}>
              {fields
                .filter((field) => field.editOptions.enabled)
                .map((field) => {
                  return (
                    <Grid item xs={12} key={field.name}>
                      <EntityEditField
                        fieldDefinition={field}
                        entity={entity}
                        formRef={register}
                        formErrors={errors}
                        formControl={control}
                        watch={watch}
                        setValue={setValue}
                      />
                    </Grid>
                  );
                })}
              <FormMessage message={errorMessage} severity="error" />
              <FormMessage message={formMessage} severity="success" />
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item>
                <DataZone loading={queryLoading} loadingType="hidden">
                  <Button
                    disabled={mutationLoading}
                    type="submit"
                    aria-label="Save changes"
                    variant="contained"
                    color="secondary"
                  >
                    Save changes
                  </Button>
                </DataZone>
              </Grid>
              <Grid item>
                <ViewEntityButton entityDefinition={entityDefinition} entityId={id} />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </DataZone>
  );
}
