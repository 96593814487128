import React from 'react';
import SitePage from '../../site/SitePage';
import { SiteTable } from '../../table/SiteTable';
import { EntityDefinition } from '../entityTypes';
import { buildListQuery } from './entityQueryBuilder';

export interface Props {
  entityDefinition: EntityDefinition;
}

export function EntityListPage(props: Props) {
  const { entityDefinition } = props;
  const listQuery = buildListQuery(entityDefinition);
  const customTableActions = entityDefinition.pages.list.actions;
  return (
    <SitePage title={entityDefinition.label}>
      <SiteTable
        key={entityDefinition.name}
        entityDefinition={entityDefinition}
        query={listQuery}
        customTableActions={customTableActions}
      />
    </SitePage>
  );
}
