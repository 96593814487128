import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '11px',
    letterSpacing: '0.8px',
    lineHeight: '16px',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
}));

export function TextTableLabel(props: Props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <Typography className={classes.text} variant="body2">
      {children}
    </Typography>
  );
}
