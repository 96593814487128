import React, { useState } from 'react';
import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import { useSiteSettings } from '../../site/SiteSettings';
import { CustomTableActionProps } from '../../table/SiteTableTypes';
import { rankingDateSet, formatDateRange } from '../../util/dateService';
import { DateRangePicker } from '../../date/DateRangePicker';

export function RankingDatePicker(props: CustomTableActionProps) {
  const { updateFilters } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { settings, setSettings } = useSiteSettings();
  const dateSet = rankingDateSet(new Date());
  const [dateLabel, setDateLabel] = useState(settings.rankingDateLabel);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const updateDateRange = (label: string, from?: Date, to?: Date) => {
    updateFilters({
      added: {
        startDate: from,
        endDate: to
      }
    });

    setSettings({
      rankingDateLabel: label,
      rankingStartDate: from,
      rankingEndDate: to
    });

    setDateLabel(label);
    closeMenu();
  };

  return (
    <>
      <Button
        aria-label="open date menu"
        aria-describedby="date-options"
        aria-controls="date-options"
        aria-haspopup="true"
        variant="outlined"
        onClick={openMenu}
      >
        Period: {dateLabel}
      </Button>
      <Menu id="date-options" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
        {dateSet.map((ds: any, index: any) => {
          return (
            <div key={index}>
              {index === 0 ? null : <Divider />}
              {ds.map((dss: any) => {
                return (
                  <MenuItem key={dss.label} dense onClick={() => updateDateRange(dss.label, dss.start, dss.end)}>
                    {dss.label}
                  </MenuItem>
                );
              })}
            </div>
          );
        })}
        <Divider />
        <DateRangePicker
          onOpen={() => closeMenu()}
          onChange={(from, to) => {
            updateDateRange(formatDateRange(from, to), from, to);
          }}
        />
      </Menu>
    </>
  );
}
