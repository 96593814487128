import React from 'react';
import { Alert, Color } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

interface Props {
  severity?: Color;
  message?: string;
}

export function FormMessage(props: Props) {
  const { message, severity = 'error' } = props;
  if (!message) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <Alert severity={severity}>{message}</Alert>
    </Grid>
  );
}
