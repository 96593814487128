import React from 'react';
import { ApolloError } from '@apollo/client';
import { ErrorCard } from '../errors/ErrorCard';
import { Skeleton } from '@material-ui/lab';
import { LoadingCard } from '../loaders/LoadingCard';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import { ErrorMessage } from '../errors/ErrorMessage';

export interface Props {
  error?: ApolloError;
  errorType?: 'hidden' | 'errorCard' | 'errorMessage';
  loading?: boolean;
  loadingMessage?: string;
  loadingType?: 'hidden' | 'skeleton' | 'spinnerCard' | 'spinner';
  children?: React.ReactNode;
  minHeight?: number;
  minWidth?: number;
}

export function DataZone(props: Props) {
  const {
    error,
    errorType = 'errorCard',
    loading = false,
    loadingMessage,
    loadingType = 'spinnerCard',
    children,
    minHeight,
    minWidth
  } = props;
  if (error) {
    switch (errorType) {
      case 'errorCard':
        return <ErrorCard message={error.message} />;
      case 'errorMessage':
        return <ErrorMessage message={error.message} />;
      case 'hidden':
        return null;
    }
  }

  if (loading) {
    switch (loadingType) {
      case 'spinnerCard':
        return <LoadingCard minHeight={minHeight} minWidth={minWidth} message={loadingMessage} />;
      case 'spinner':
        return <LoadingSpinner minHeight={minHeight} minWidth={minWidth} message={loadingMessage} />;
      case 'hidden':
        return null;
      case 'skeleton':
        return <Skeleton>{children}</Skeleton>;
    }
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
}
