import { useAppContext } from '../ranker/AppContext';
import { Roles } from '../auth/authTypes';

export const useRole = (): Roles => {
  const { context } = useAppContext();
  const user = context.user;
  return user?.role ?? 'PUBLIC';
};

export function doesRoleHaveAccess(requiredRole: Roles, userRole?: Roles): boolean {
  return roleToValue(userRole) >= roleToValue(requiredRole);
}

export function roleToValue(userRole?: Roles): number {
  switch (userRole) {
    case 'ADMIN':
      return 2;
    case 'USER':
      return 1;
    case 'PUBLIC':
      return 0;
    default:
      return 0;
  }
}
