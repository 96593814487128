import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { getEnumItemsInSentenceCase } from '../../entity/entityEnumHelper';

export const SYSTEMS = ['GREYHOUND', 'SIRE', 'DAM'];

export const scoreDefinition: EntityDefinition = createEntityDefinition({
  name: 'score',
  label: 'Score',
  pages: {
    view: {
      enabledForRole: 'ADMIN',
      canEdit: false
    },
    create: {
      enabled: false
    },
    edit: {
      enabled: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'amount',
      label: 'Points',
      type: 'integer',
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'system',
      label: 'System',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getEnumItemsInSentenceCase(SYSTEMS)
        }
      }
    },
    {
      name: 'raceField',
      label: 'Placing',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'raceField',
          id: 'id',
          value: 'id',
          parentField: 'raceFieldId'
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('score', scoreDefinition);
