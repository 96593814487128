import React from 'react';
import { NavButton } from '../../navigation/NavButton';
import { EntityDefinition } from '../entityTypes';
import EditIcon from '@material-ui/icons/Edit';
import { getNameDashPlural } from '../entityHelper';

interface Props {
  label?: string;
  color?: string;
  entityDefinition?: EntityDefinition;
  entityId?: string;
}

export function EditEntityButton(props: Props) {
  const { label = 'Edit', entityDefinition, entityId } = props;

  if (!entityId || !entityDefinition) {
    return null;
  }

  return (
    <NavButton
      label={label}
      color="primary"
      icon={<EditIcon />}
      location={`/${getNameDashPlural(entityDefinition.name)}/${entityId}/edit`}
    />
  );
}
