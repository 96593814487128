import React from 'react';
import SitePage from '../../site/SitePage';
import { EntityDefinition } from '../entityTypes';
import { buildUpdateQuery, buildViewQuery } from './entityQueryBuilder';
import { EntityEditCard } from '../EntityEditCard';
import { DataZone } from '../../zones/DataZone';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { EntityChildListCard } from '../EntityChildListCard';

export interface Props {
  entityDefinition: EntityDefinition;
}

export function EntityEditPage(props: Props) {
  const { entityDefinition } = props;
  let { id } = useParams();
  const viewQuery = buildViewQuery(entityDefinition);
  const updateQuery = buildUpdateQuery(entityDefinition);
  const { loading, error, data } = useQuery(viewQuery, {
    variables: { id }
  });
  const editComponents = entityDefinition.pages.edit.components;
  const childLists = entityDefinition.pages.edit.childLists;
  const entity = data && data[entityDefinition.name] ? data[entityDefinition.name] : {};
  return (
    <SitePage title={`Edit ${entityDefinition.label}`}>
      <DataZone loadingType="spinner" loading={loading} error={error}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <EntityEditCard entityDefinition={entityDefinition} viewQuery={viewQuery} editQuery={updateQuery} />
          </Grid>
          {editComponents.map((EditComponent, index) => {
            return (
              <Grid key={`${entityDefinition.name}-edit-component-${index}`} item xs={12} md={6}>
                <EditComponent entityDefinition={entityDefinition} entity={entity} />
              </Grid>
            );
          })}
          {childLists.map((childListDefinition) => {
            return (
              <Grid key={`${entityDefinition.name}-${childListDefinition.name}`} item xs={12} md={6}>
                <EntityChildListCard
                  entityDefinition={entityDefinition}
                  entity={entity}
                  childListDefinition={childListDefinition}
                />
              </Grid>
            );
          })}
        </Grid>
      </DataZone>
    </SitePage>
  );
}
