import React from 'react';
import { SiteTableExport } from '../../table/SiteTableExport';
import { CustomTableActionProps } from '../../table/SiteTableTypes';
import { doesRoleHaveAccess, useRole } from '../../zones/zoneHelper';

export function RankingCsvExport(props: CustomTableActionProps) {
  const { entityDefinition, rows, columnVisibilities } = props;
  const role = useRole();
  const hasAccess = doesRoleHaveAccess('ADMIN', role);
  if (!hasAccess) {
    return null;
  }
  return (
    <SiteTableExport
      entityDefinition={entityDefinition}
      columnVisibilities={columnVisibilities}
      rows={rows}
      maxColumnLength={34}
      includeColumnOption={true}
    />
  );
}
