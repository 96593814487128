export enum TrackLocationType {
  METRO = 'METRO',
  COUNTRY = 'COUNTRY'
}

export enum TrackSurface {
  LOAM = 'LOAM',
  SAND = 'SAND',
  GRASS = 'GRASS'
}

export enum TrackStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}
