import React from 'react';
import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from '../../util/dateService';
import { EntityViewProps } from './EntityViewProps';
import { Typography } from '@material-ui/core';

export function formatToStandardDateString(date: Date | string | undefined | null): string {
  if (date === undefined || date === null) {
    return '';
  }
  return dayjs(date).format(STANDARD_DATE_FORMAT);
}

export function EntityViewDateField(props: EntityViewProps) {
  const { value } = props;
  return <Typography>{formatToStandardDateString(value)}</Typography>;
}
