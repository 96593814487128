import React from 'react';
import { NavButton } from '../../navigation/NavButton';
import { CustomTableActionProps } from '../../table/SiteTableTypes';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export function RaceImportButton(props: CustomTableActionProps) {
  return (
    <NavButton label="Import" icon={<CloudDownloadIcon />} location="/race-import" size="small" color="primary" />
  );
}
