import { gql } from '@apollo/client';

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile on User {
    id
    name
    role
    email
    imageUrl
    createdAt
    updatedAt
  }
`;

export const USER_PROFILE_QUERY = gql`
  query UserProfileQuery {
    viewer {
      user {
        ...UserProfile
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;
