import React from 'react';
import NumberFormat from 'react-number-format';

interface CurrencyFieldProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function CurrencyField(props: CurrencyFieldProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      autoComplete="off"
      thousandSeparator
      isNumericString
      decimalScale={0}
      prefix="$"
      data-lpignore="true"
    />
  );
}
