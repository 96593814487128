import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormMessage } from './FormMessage';
import { FormFieldDefinition } from './FormFieldDefinition';
import { buildValidationSchemaForFormFieldDefinition } from './formHelper';
import { FormField } from './FormField';

interface Props {
  onFormSubmit: (result: any) => void;
  submitLabel: string;
  fields: FormFieldDefinition[];
  cancelLabel?: string;
  onFormCancel?: () => void;
}

export function BasicForm(props: Props) {
  const { fields, onFormSubmit, onFormCancel, cancelLabel, submitLabel } = props;
  const validationSchema = buildValidationSchemaForFormFieldDefinition(fields);
  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const [formMessage, setFormMessage] = useState<string | undefined>(undefined);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (formData: any) => {
    setLoading(true);
    try {
      await onFormSubmit(formData);
      setLoading(false);
      setFormMessage('Successful');
    } catch (error) {
      setLoading(false);
      console.error('mutation error', error);
      setFormErrorMessage(error.message);
    }
  };

  const onCancel = async () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={3}>
        {fields
          .filter((field) => field.enabled)
          .map((field) => {
            return (
              <Grid item xs={12} key={field.name}>
                <FormField
                  fieldDefinition={field}
                  formRef={register}
                  formErrors={errors}
                  formControl={control}
                  watch={watch}
                  setValue={setValue}
                />
              </Grid>
            );
          })}
        <FormMessage message={formErrorMessage} severity="error" />
        <FormMessage message={formMessage} severity="success" />
      </Grid>
      <Grid container spacing={2}>
        {cancelLabel ? (
          <Grid item>
            <Button disabled={loading} variant="contained" onClick={onCancel}>
              {cancelLabel}
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <Button disabled={loading} variant="contained" color="primary" type="submit">
            {submitLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
