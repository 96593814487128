import React, { useEffect, useState } from 'react';
import { FieldDefinition, FieldDefinitionTypeOptionEntity } from '../entityTypes';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql, useQuery } from '@apollo/client';
import { FilterChanges } from '../../table/tableTypes';
import useDebounce from '../../util/useDebounce';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberNew from '@material-ui/icons/FiberNew';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import { getNameCamelPlural, getNameTitle } from '../entityHelper';

interface Props {
  id?: string;
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
  formControl: any;
}

const useStyles = makeStyles((theme) => ({
  input: ({ changeCase }: any) => ({
    textTransform: changeCase
  })
}));

export function buildEntityQuery(entityLookupDefinition?: FieldDefinitionTypeOptionEntity) {
  if (!entityLookupDefinition) {
    throw new Error('invalid entity definition');
  }
  const name = entityLookupDefinition.type;
  const pluralName = getNameCamelPlural(name);
  const titleCaseName = getNameTitle(name);
  return gql(`
  query Lookup${titleCaseName}Query($first: Int, $filters: ${titleCaseName}Filters) {
    ${pluralName}(first: $first, filters: $filters) {
      edges {
        node {
          ${entityLookupDefinition.id}
          ${entityLookupDefinition.value}
        }
      }
    }
  }
`);
}

export function EntityFindOrCreator(props: Props) {
  const { id = 'find-or-create', fieldDefinition, formControl, entity } = props;
  const entityLookupDefinition = fieldDefinition.typeOptions.entity;
  const canCreate = fieldDefinition.typeOptions.entity?.canCreate ?? true;
  const formFieldName = entityLookupDefinition?.parentField ?? '';
  const changeCase = fieldDefinition?.typeOptions.string?.changeCase ?? 'none';
  const classes = useStyles({ changeCase });
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState({});
  const [isNew, setIsNew] = useState(false);

  // setup search and filter functions
  const handleFilterChange = (filterChanges: FilterChanges) => {
    if (filterChanges.added) {
      setFilters({ ...filters, ...filterChanges.added });
    }

    if (filterChanges.removed) {
      const clearedFilters: any = {
        ...filters
      };
      Object.keys(filterChanges.removed).forEach((key) => {
        delete clearedFilters[key];
      });
      setFilters(clearedFilters);
    }
  };

  const debouncedSearchValue = useDebounce(inputValue, 250);

  useEffect(() => {
    const valueToFind = debouncedSearchValue;
    if (valueToFind) {
      handleFilterChange({ added: { OR: { [`${entityLookupDefinition?.value}_contains`]: valueToFind } } });
    } else {
      handleFilterChange({ removed: { OR: null } });
    }
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  // data loading
  const { loading, data } = useQuery(buildEntityQuery(entityLookupDefinition), {
    variables: {
      first: 10,
      filters
    },
    fetchPolicy: 'cache-and-network'
  });

  const queryField = `${getNameCamelPlural(entityLookupDefinition?.type ?? '')}`;
  const entities = data?.[queryField];
  const edges = entities?.edges;
  const nodes = edges?.map((edge: any) => edge.node) ?? [];

  // rendering
  return (
    <Controller
      control={formControl}
      name={formFieldName}
      defaultValue={entity[fieldDefinition.name]}
      render={(controllerProps) => (
        <Autocomplete
          {...controllerProps}
          selectOnFocus
          clearOnBlur
          openOnFocus={false}
          handleHomeEndKeys
          freeSolo={canCreate}
          id={id}
          options={nodes}
          onChange={(event, newValue) => {
            if (newValue !== null && newValue !== undefined && newValue.id === '$new') {
              setIsNew(true);
              controllerProps.onChange({
                id: newValue.id,
                name: newValue.name
              });
            } else {
              setIsNew(false);
              controllerProps.onChange(newValue);
            }
          }}
          onInputChange={(event, inputValue, reason) => {
            setInputValue(inputValue);
          }}
          filterOptions={(options, params) => {
            let filterValue = params.inputValue;
            if (fieldDefinition?.typeOptions?.string?.changeCase === 'uppercase') {
              filterValue = filterValue.toUpperCase();
            }
            if (filterValue !== '' && options.find((item) => item.name === filterValue) === undefined && canCreate) {
              options.push({
                id: '$new',
                name: filterValue
              });
            }
            return options;
          }}
          renderOption={(option: any) => {
            return option.id === '$new' ? `Add "${option.name}"` : option.name;
          }}
          getOptionLabel={(option: any) => {
            return option.name;
          }}
          renderInput={(params) => {
            (params as any).inputProps['data-lpignore'] = 'true';
            return (
              <TextField
                {...params}
                label={fieldDefinition.label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  classes,
                  endAdornment: (
                    <React.Fragment>
                      {isNew ? <FiberNew /> : null}
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            );
          }}
        />
      )}
    />
  );
}
