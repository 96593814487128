import 'isomorphic-fetch';
import { getAuth0Client } from '../auth/authHelper';

export type WrappedFetchOptions = RequestInit;

export async function wrappedFetch(uri: string, options: WrappedFetchOptions) {
  const authHeaders = await getAuthHeaders();

  options.headers = {
    ...options.headers,
    ...authHeaders
  };

  return fetch(uri, options);
}

export async function getAuthHeaders(): Promise<object> {
  const token = await getJwt();
  if (token === undefined) {
    return {};
  } else {
    return {
      Authorization: `Bearer ${token}`
    };
  }
}

export async function getJwt(): Promise<string | undefined> {
  const auth0Client = await getAuth0Client();
  const claims = await auth0Client.getIdTokenClaims();
  return claims?.__raw;
}
