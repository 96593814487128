import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { MyProfileButton } from '../user/MyProfileButton';
import { useAppContext } from '../ranker/AppContext';
import { login } from '../auth/authHelper';

export interface Props {
  toggleMenu: () => void;
}

const lightColor = 'rgba(255, 255, 255, 0.7)';
const useStyles = makeStyles((theme) => ({
  mainContent: {
    flex: 1,
    padding: '48px 16px',
    background: '#eaeff1',
    [theme.breakpoints.up('sm')]: {
      padding: '48px 24px'
    }
  },
  menuButton: {
    marginLeft: -theme.spacing(1)
  },
  iconButtonAvatar: {
    padding: 4
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  button: {
    borderColor: lightColor
  }
}));

function SiteAuthHeader(props: Props) {
  const { toggleMenu } = props;
  const { context } = useAppContext();
  const { isAuthenticated = false } = context;
  const classes = useStyles();

  return (
    <AppBar data-id="app-bar-site" color="primary" position="sticky" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Hidden lgUp>
            <Grid item>
              <IconButton color="inherit" aria-label="Open drawer" onClick={toggleMenu} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          {!isAuthenticated && (
            <Grid item>
              <Button data-id="button-login" color="inherit" onClick={() => login()}>
                Login
              </Button>
            </Grid>
          )}
          {isAuthenticated && (
            <Grid item>
              <MyProfileButton />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default SiteAuthHeader;
