import { Roles } from '../auth/authTypes';
import {
  EntityDefinition,
  EntityNavToType,
  FieldDefinition,
  FieldDefinitionEnumTypeItem,
  FieldDefinitionTypeOptionEntity,
  FieldParseOptions,
  FieldParser,
  PartialEntityDefinition,
  PartialFieldDefinition
} from './entityTypes';
import { parseNumber } from '../util/numberHelper';
import * as yup from 'yup';
import { ObjectSchema, Schema } from 'yup';
import { ApolloClient, gql } from '@apollo/client';
import { pascalCase, paramCase, camelCase } from 'change-case';
import dayjs from 'dayjs';
import { formatToStandardDateString } from './viewFields/EntityViewDateField';
import { uniqBy } from 'lodash';
import { isPresent } from '../util/objectHelper';

export const defaultActions = {
  canCreate: true,
  canEdit: true,
  canDelete: true
};

export const defaultPages = {
  list: {
    enabled: true,
    canCreate: true,
    orderBy: 'updatedAt_DESC',
    actions: []
  },
  view: {
    enabled: true,
    enabledForRole: 'PUBLIC' as Roles,
    canEdit: true,
    canDelete: true,
    actions: [],
    childLists: []
  },
  edit: {
    enabled: true,
    components: [],
    childLists: [],
    onSaveNavTo: 'view' as EntityNavToType
  },
  create: {
    enabled: true,
    onSaveNavTo: 'view' as EntityNavToType
  }
};

export const defaultEntityDefinition = {
  name: 'defaultEntity'
};

export const defaultTableOptions = {
  enabled: true,
  sortable: false,
  canFilter: false,
  visibleByDefault: true
};

export const defaultViewOptions = {
  enabled: true,
  selfLink: false
};

export const defaultEditOptions = {
  enabled: true,
  mandatory: false
};

export const defaultFieldDefinition: FieldDefinition = {
  name: 'defaultField',
  label: 'fieldLabel',
  type: 'string',
  fieldArgs: [],
  typeOptions: {},
  tableOptions: defaultTableOptions,
  viewOptions: defaultViewOptions,
  editOptions: defaultEditOptions
};

export function createEntityDefinition(entityDefinition: PartialEntityDefinition): EntityDefinition {
  const fields = (entityDefinition.fields ?? []).map(createFieldDefinition);
  return {
    ...defaultEntityDefinition,
    ...entityDefinition,
    pages: {
      view: {
        ...defaultPages.view,
        ...entityDefinition.pages?.view
      },
      list: {
        ...defaultPages.list,
        ...entityDefinition.pages?.list
      },
      edit: {
        ...defaultPages.edit,
        ...entityDefinition.pages?.edit
      },
      create: {
        ...defaultPages.create,
        ...entityDefinition.pages?.create
      }
    },
    fields
  };
}

export function createFieldDefinition(field: PartialFieldDefinition): FieldDefinition {
  return {
    ...defaultFieldDefinition,
    ...field,
    tableOptions: {
      ...defaultTableOptions,
      ...field.tableOptions
    },
    viewOptions: {
      ...defaultViewOptions,
      ...field.viewOptions
    },
    editOptions: {
      ...defaultEditOptions,
      ...field.editOptions
    }
  };
}

export function defaultTableAlignment(fieldDefinition: FieldDefinition): 'left' | 'right' {
  switch (fieldDefinition.type) {
    case 'string':
      return 'left';
    case 'date':
      return 'left';
    case 'boolean':
      return 'left';
    case 'currency':
      return 'left';
    case 'enum':
      return 'left';
    case 'integer':
      return 'left';
    case 'float':
      return 'left';
    default:
      return 'left';
  }
}

export function getFieldForFieldDefinition(fieldDefinition: FieldDefinition, entity: any): any {
  if (!fieldDefinition || !entity) {
    return undefined;
  }
  return entity[fieldDefinition.name];
}

export function getBaseFieldValidation(fieldDefinition: FieldDefinition): any {
  switch (fieldDefinition.type) {
    case 'string':
      return yup.string().max(50);
    case 'date':
      return getDateFieldValidation(fieldDefinition);
    case 'currency':
      return yup.string();
    case 'enum':
      return undefined;
    case 'boolean':
      return yup.boolean();
    case 'integer':
      return yup.string();
    case 'float':
      return yup.string();
    default:
      return undefined;
  }
}

export function getDateFieldValidation(fieldDefinition: FieldDefinition): any {
  const maxOption = fieldDefinition.typeOptions.date?.max;
  let base = yup
    .date()
    .nullable()
    .label(fieldDefinition.label)
    .transform((value, originalValue) =>
      originalValue !== undefined && originalValue !== null ? dayjs(originalValue).toDate() : null
    )
    .min(
      new Date(1700, 0, 1),
      ({ min }) => `${fieldDefinition.label} field must be later than ${formatToStandardDateString(min)}`
    );

  if (maxOption === 'now') {
    base = base.max(
      new Date(),
      ({ max }) => `${fieldDefinition.label} field must be at earlier than ${formatToStandardDateString(max)}`
    );
  }
  return base;
}

export function getFieldValidation(fieldDefinition: FieldDefinition): Schema<any> | undefined {
  let baseSchema = getBaseFieldValidation(fieldDefinition);
  if (!baseSchema) {
    return undefined;
  }
  if (fieldDefinition.editOptions.mandatory) {
    baseSchema = baseSchema.required();
  } else {
    baseSchema = baseSchema.notRequired();
  }
  return baseSchema;
}

export function buildValidationSchemaForEntityDefinition(fieldDefinitions: FieldDefinition[]): ObjectSchema {
  const fieldValidations = fieldDefinitions
    .filter((field) => field.editOptions.enabled)
    .reduce((schema, field) => {
      const validation = getFieldValidation(field);
      if (validation) {
        schema[field.name] = validation;
      }
      return schema;
    }, {} as any);
  return yup.object().shape(fieldValidations);
}

export async function parseFormDataForSubmission(
  apolloClient: ApolloClient<any>,
  givenFields: FieldDefinition[],
  formData: any
): Promise<any> {
  const createdEntities = await createEntitiesForFormData(givenFields, formData, apolloClient);

  const fields = Object.entries<any>(formData).map(([key, value]) => {
    const fieldDefinition = givenFields.find(
      (f) => f.name === key || (f.type === 'entity' && f.typeOptions.entity?.parentField === key)
    );
    if (!fieldDefinition) {
      return [key, value];
    }

    const parser = getFormDataParserForField(fieldDefinition);
    if (!parser) {
      return [key, value];
    }

    let parsedValue = parser(value, { fieldDefinition, createdEntities });
    return [key, parsedValue];
  });

  return fields.reduce((ob: any, [key, value]) => {
    ob[key] = value;
    return ob;
  }, {});
}

export function getFormDataParserForField(fieldDefinition: FieldDefinition): FieldParser | undefined {
  switch (fieldDefinition.type) {
    case 'string':
      return undefined;
    case 'date':
      return undefined;
    case 'boolean':
      return undefined;
    case 'currency':
      return parseNumber;
    case 'enum':
      return parseFormEnum;
    case 'integer':
      return parseNumber;
    case 'float':
      return parseNumber;
    case 'entity':
      return parseFormFieldEntity;
    case 'custom':
      return undefined;
    default:
      return undefined;
  }
}

export function parseFormEnum(value: FieldDefinitionEnumTypeItem | undefined) {
  if (value === null || value === undefined) {
    return undefined;
  }
  return value.value;
}

export function parseFormFieldEntity(
  value: any | undefined | null,
  fieldParserOptions: FieldParseOptions
): string | null {
  const { fieldDefinition, createdEntities } = fieldParserOptions;
  if (
    value === undefined ||
    value === null ||
    fieldDefinition === undefined ||
    fieldDefinition.typeOptions.entity === undefined
  ) {
    return null;
  }
  const fieldId = value[fieldDefinition.typeOptions.entity.id];
  if (fieldId === '$new') {
    const createdEntity = createdEntities.find((ce) => ce.fieldDefinition.name === fieldDefinition.name);
    if (createdEntity !== undefined) {
      return createdEntity.newEntityId;
    } else {
      return null;
    }
  } else {
    return fieldId;
  }
}

export async function createEntitiesForFormData(
  fields: FieldDefinition[],
  formData: any,
  apolloClient: ApolloClient<any>
) {
  const entitiesToCreate: { fieldDefinition: FieldDefinition; value: any }[] = Object.entries<any>(formData)
    .map(([key, value]) => {
      const fieldDefinition = fields.find(
        (f) => f.name === key || (f.type === 'entity' && f.typeOptions.entity?.parentField === key)
      );
      if (!fieldDefinition || value === null || value === undefined) {
        return undefined;
      }

      if (fieldDefinition.type !== 'entity' || fieldDefinition.typeOptions.entity === undefined) {
        return undefined;
      }

      const fieldId = value[fieldDefinition.typeOptions.entity.id];
      if (fieldId !== '$new') {
        return undefined;
      }

      const newFieldValue = value[fieldDefinition.typeOptions.entity.value];
      return {
        fieldDefinition,
        value: newFieldValue
      };
    })
    .filter(isPresent);

  const uniqueEntitiesToCreate = uniqBy(
    entitiesToCreate,
    (etc) => `${etc.fieldDefinition.typeOptions.entity?.type}_${etc.value}`
  );

  const createdEntities = await createFormFieldEntities(uniqueEntitiesToCreate, apolloClient);

  return entitiesToCreate
    .map((etc) => {
      const foundCreatedEntity = createdEntities.find((ce) => {
        const etcId = `${etc.fieldDefinition.typeOptions.entity?.type}_${etc.value}`;
        const ceNewFieldValue = ce?.newEntity[ce?.fieldDefinition.typeOptions.entity?.value ?? ''];
        const ceId = `${ce?.fieldDefinition.typeOptions.entity?.type}_${ceNewFieldValue}`;
        return ceId === etcId;
      });
      if (!foundCreatedEntity) {
        return undefined;
      }
      return {
        fieldDefinition: etc.fieldDefinition,
        newEntityId: foundCreatedEntity.newEntityId
      };
    })
    .filter(isPresent);
}

export async function createFormFieldEntities(
  newEntities: { fieldDefinition: FieldDefinition; value: any }[],
  apolloClient: ApolloClient<any>
) {
  return Promise.all(
    newEntities.map(async (ne) => {
      return createFormFieldEntity(ne.value, ne.fieldDefinition, apolloClient);
    })
  );
}

export async function createFormFieldEntity(
  value: any | undefined | null,
  fieldDefinition: FieldDefinition,
  apolloClient: ApolloClient<any>
): Promise<{ fieldDefinition: FieldDefinition; newEntityId: string; newEntity: any } | undefined> {
  if (
    value === undefined ||
    value === null ||
    fieldDefinition === undefined ||
    fieldDefinition.typeOptions.entity === undefined
  ) {
    return undefined;
  }

  const newEntity = await createEntityRef(apolloClient, fieldDefinition.typeOptions.entity, value);
  return {
    fieldDefinition,
    newEntityId: newEntity.id,
    newEntity
  };
}

export function getFieldValueForEntityField(value: any | undefined | null, fieldDefinition?: FieldDefinition): string {
  if (
    value === undefined ||
    value === null ||
    fieldDefinition === undefined ||
    fieldDefinition.typeOptions.entity === undefined
  ) {
    return '';
  }
  return value[fieldDefinition.typeOptions.entity.value];
}

export function getFieldIdForEntityField(value: any | undefined | null, fieldDefinition?: FieldDefinition): string {
  if (
    value === undefined ||
    value === null ||
    fieldDefinition === undefined ||
    fieldDefinition.typeOptions.entity === undefined
  ) {
    return '';
  }
  return value[fieldDefinition.typeOptions.entity.id];
}

export function parseFieldIdForEntityField(
  value: any | undefined | null,
  fieldDefinition?: FieldDefinition
): string | null {
  if (
    value === undefined ||
    value === null ||
    fieldDefinition === undefined ||
    fieldDefinition.typeOptions.entity === undefined
  ) {
    return null;
  }

  const fieldId = value[fieldDefinition.typeOptions.entity.id];
  if (fieldId === '$new') {
    return null;
  }

  return fieldId;
}

export async function createEntityRef(
  apolloClient: ApolloClient<any>,
  fieldDefinitionTypeOptionEntity: FieldDefinitionTypeOptionEntity,
  value: any
) {
  const name = fieldDefinitionTypeOptionEntity.type;
  const nameTitle = pascalCase(name);
  const mutation = gql(`
    mutation ${nameTitle}Create($input: ${nameTitle}CreateInput!) {
      create${nameTitle}(input: $input) {
        ${name} {
          ${fieldDefinitionTypeOptionEntity.id}
          ${fieldDefinitionTypeOptionEntity.value}
        }
      }
    }
  `);
  const variables = {
    input: {
      [fieldDefinitionTypeOptionEntity.value]: value
    }
  };
  const { data } = await apolloClient.mutate({ mutation, variables });
  return data[`create${nameTitle}`][name];
}

export function getNameTitle(name: string): string {
  return pascalCase(name);
}

export function getNameCamel(name: string): string {
  return camelCase(name);
}

export function getNameDash(name: string): string {
  return paramCase(name);
}

export function getNameCamelPlural(name: string): string {
  return `${getNameCamel(name)}s`;
}

export function getNameDashPlural(name: string): string {
  return `${getNameDash(name)}s`;
}

export function getEntityNavTarget(entityDefinition: EntityDefinition, type: EntityNavToType, entity: any): string {
  const namePlural = getNameDashPlural(entityDefinition.name);
  if (type === 'edit') {
    return `/${namePlural}/${entity.id}/edit`;
  }

  if (type === 'list') {
    return `/${namePlural}`;
  }
  // view
  return `/${namePlural}/${entity.id}`;
}

export function entityEditNavTo(
  entityDefinition: EntityDefinition,
  returnTo: string | null,
  entity: any,
  history: any
) {
  if (returnTo !== null) {
    history.push(returnTo);
  } else {
    history.push(getEntityNavTarget(entityDefinition, entityDefinition.pages.edit.onSaveNavTo, entity));
  }
}

export function entityCreateNavTo(
  entityDefinition: EntityDefinition,
  returnTo: string | null,
  entity: any,
  history: any
) {
  if (returnTo !== null) {
    history.push(returnTo);
  } else {
    history.push(getEntityNavTarget(entityDefinition, entityDefinition.pages.create.onSaveNavTo, entity));
  }
}

export function buildEntityFromQueryParams(entityDefinition: EntityDefinition, queryParams: URLSearchParams) {
  const result: any = {};
  entityDefinition.fields.forEach((field) => {
    const foundFieldValue = queryParams.get(field.name);
    if (foundFieldValue !== undefined && foundFieldValue !== null) {
      if (field.type === 'entity') {
        result[field.name] = JSON.parse(foundFieldValue);
      } else {
        result[field.name] = foundFieldValue;
      }
    }
  });
  return result;
}
