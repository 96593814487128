import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const importReferenceDefinition: EntityDefinition = createEntityDefinition({
  name: 'importReference',
  label: 'Import Reference',
  pages: {
    list: {
      canCreate: false
    },
    view: {
      canEdit: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'sourceType',
      label: 'Source Type',
      type: 'string'
    },
    {
      name: 'sourceSystem',
      label: 'Source System',
      type: 'string'
    },
    {
      name: 'log',
      label: 'Log',
      type: 'code',
      typeOptions: {
        code: {
          language: 'text'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'json',
      label: 'JSON',
      type: 'code',
      tableOptions: {
        visibleByDefault: false
      },
      typeOptions: {
        code: {
          language: 'json'
        }
      }
    },
    {
      name: 'xml',
      label: 'XML',
      type: 'code',
      typeOptions: {
        code: {
          language: 'xml'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('importReference', importReferenceDefinition);
