import dayjs from 'dayjs';
import createPersistedState from 'use-persisted-state';
import { getDateFromString, rankingDateSet } from '../util/dateService';

const useSettingState = createPersistedState('site.settings');

export interface SiteSettingsStorage {
  rankingDateLabel?: string;
  rankingStartDate?: string;
  rankingEndDate?: string;
  expires?: string;
}

export interface SiteSettings {
  rankingDateLabel?: string;
  rankingStartDate: Date;
  rankingEndDate: Date;
}

export interface SiteSettingsFields {
  settings: SiteSettings;
  setSettings: (settings: Partial<SiteSettings>) => void;
}

export function checkRawSettingsExpiry(rawSettingsInput?: SiteSettingsStorage): SiteSettingsStorage | undefined {
  if (rawSettingsInput === undefined) {
    return rawSettingsInput;
  }
  if (rawSettingsInput.expires) {
    const expiryDate = getDateFromString(rawSettingsInput.expires);
    if (dayjs(expiryDate).isAfter(dayjs())) {
      return rawSettingsInput;
    } else {
      return {};
    }
  } else {
    return rawSettingsInput;
  }
}

export function convertRawSettings(rawSettingsInput?: SiteSettingsStorage): SiteSettings {
  const rawSettings = checkRawSettingsExpiry(rawSettingsInput);
  const dateSet = rankingDateSet(new Date());
  const rankingDateLabel = rawSettings?.rankingDateLabel ?? dateSet[0][0].label;
  const rankingStartDate = getDateFromString(rawSettings?.rankingStartDate, dateSet[0][0].start);
  const rankingEndDate = getDateFromString(rawSettings?.rankingEndDate, dateSet[0][0].end);
  return {
    rankingDateLabel,
    rankingStartDate,
    rankingEndDate
  };
}

export function mergeRawSettings(
  oldRawSettings?: SiteSettingsStorage,
  newRawSettings?: SiteSettingsStorage
): SiteSettingsStorage {
  return {
    ...oldRawSettings,
    ...newRawSettings
  };
}

export function convertSettingsIntoRaw(settings?: Partial<SiteSettings>): SiteSettingsStorage {
  const rankingStartDate = settings?.rankingStartDate?.toISOString();
  const rankingEndDate = settings?.rankingEndDate?.toISOString();
  return {
    rankingDateLabel: settings?.rankingDateLabel,
    rankingStartDate,
    rankingEndDate,
    expires: dayjs().add(7, 'day').toISOString()
  };
}

export const useSiteSettings = (): SiteSettingsFields => {
  const [rawSettings, rawSetter] = useSettingState<SiteSettingsStorage>({});

  const settings = convertRawSettings(rawSettings);
  const setSettings = (newSettings?: Partial<SiteSettings>) => {
    rawSetter(mergeRawSettings(rawSettings, convertSettingsIntoRaw(newSettings)));
  };

  return { settings, setSettings };
};
