import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import { ColumnVisibility } from './tableTypes';
import { filterInvisibleColumns } from './tableService';
import { EntityDefinition, FieldDefinition } from '../entity/entityTypes';
import { defaultTableAlignment } from '../entity/entityHelper';
import { EntityViewField } from '../entity/viewFields/EntityViewField';

interface Props<EntityKind> {
  entityDefinition: EntityDefinition;
  columnVisibilities: Array<ColumnVisibility>;
  rows: Array<EntityKind>;
  loading?: boolean;
  rowsPerPage: number;
  padHeight?: boolean;
}

interface Node {
  id: string;
}

export function SiteTableBody<EntityKind extends Node>(props: Props<EntityKind>) {
  const { entityDefinition, rows, loading = false, rowsPerPage, columnVisibilities, padHeight = true } = props;
  const columns = entityDefinition.fields;
  const showColumns = filterInvisibleColumns(columns, columnVisibilities);
  const isEmpty = rows.length === 0;
  const emptyRowCount = padHeight ? rowsPerPage - rows.length : 0;
  const emptyRowHeight = padHeight ? 57 * emptyRowCount : 0;
  const totalRowsHeight = 57 * rowsPerPage;
  const noneFoundRowHeight = padHeight ? totalRowsHeight : 0;

  if (loading) {
    return (
      <TableBody>
        <TableRow style={{ height: totalRowsHeight }}>
          <TableCell colSpan={showColumns.length} align="center">
            <LoadingSpinner message="Loading..." />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (isEmpty) {
    return (
      <TableBody>
        <TableRow style={{ height: noneFoundRowHeight }}>
          <TableCell colSpan={showColumns.length} align="center">
            No results found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row: EntityKind) => {
        return (
          <TableRow hover key={row.id}>
            {showColumns.map((column: FieldDefinition) => {
              const alignment = defaultTableAlignment(column);
              return (
                <TableCell key={column.name} align={alignment}>
                  <EntityViewField fieldDefinition={column} entity={row} entityDefinition={entityDefinition} />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
      {emptyRowCount > 0 && (
        <TableRow style={{ height: emptyRowHeight }}>
          <TableCell colSpan={showColumns.length} />
        </TableRow>
      )}
    </TableBody>
  );
}
