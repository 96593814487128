import React from 'react';
import SitePage from '../../site/SitePage';

interface Props {}

export function TrackListPage(props: Props) {
  return (
    <SitePage title="Tracks">
      <div>todo</div>
    </SitePage>
  );
}
