import { FieldProps } from '../../entity/entityTypes';
import React from 'react';
import { Typography } from '@material-ui/core';

const suffixes = ['st', 'nd', 'rd'];

export function RaceFieldPlaceField(props: FieldProps) {
  const { value, entity } = props;
  if ((entity as any).disqualified === true) {
    return <Typography>Disqualified</Typography>;
  }
  if ((entity as any).didNotFinish === true) {
    return <Typography>DNF</Typography>;
  }
  const placing = (value as any) as number | null;
  if (placing === null) {
    return  <Typography>Unknown</Typography>;
  }
  const suffix = placing > 0 && placing < 4 ? suffixes[placing - 1] : 'th';
  return <Typography>{`${placing}${suffix}`}</Typography>;
}
