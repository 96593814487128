import React from 'react';
import { NavButton } from '../../navigation/NavButton';
import { EntityDefinition } from '../entityTypes';
import AddIcon from '@material-ui/icons/Add';
import { getNameDashPlural } from '../entityHelper';

interface Props {
  label?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  entityDefinition?: EntityDefinition;
  createParams?: Record<string, string>;
}

export function CreateEntityButton(props: Props) {
  const { label = 'Create', entityDefinition, createParams = {}, size = 'medium' } = props;
  if (!entityDefinition) {
    return null;
  }
  const entityUrlName = getNameDashPlural(entityDefinition.name);
  const params = new URLSearchParams(createParams).toString();

  let url = `/${entityUrlName}/create`;
  if (params !== undefined && params.length > 0) {
    url = `${url}?${params}`;
  }
  return <NavButton label={label} color="primary" size={size} icon={<AddIcon />} location={url} />;
}
