import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const fastTrackRaceDefinition: EntityDefinition = createEntityDefinition({
  name: 'fastTrackRace',
  label: 'Fast Track Race',
  pages: {
    list: {
      canCreate: false
    },
    view: {
      canEdit: false,
      childLists: [
        {
          name: 'fields',
          childField: 'fastTrackRace',
          title: 'Fields',
          type: 'fastTrackRaceDog',
          filterBy: [{ childFilter: 'fastTrackRaceId_equal', entityField: 'id' }],
          orderBy: 'place_ASC',
          overridePageSize: 10,
          canCreate: true,
          overrideFields: ['place', 'dogName', 'trainer']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'raceName',
      label: 'Race Name',
      type: 'string',
      viewOptions: {
        selfLink: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
    },
    {
      name: 'date',
      label: 'Date',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'raceGrade',
      label: 'Race Grade',
      type: 'string'
    },
    {
      name: 'distance',
      label: 'Distance',
      type: 'string'
    },
    {
      name: 'fastTrackTrack',
      label: 'Track',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'fastTrackTrack',
          id: 'id',
          value: 'name',
          parentField: 'fastTrackTrack'
        }
      }
    },
    {
      name: 'raceTime',
      label: 'Race Time',
      type: 'string'
    },
    {
      name: 'fastTrackRaceId',
      label: 'Fast Track Race Id',
      type: 'string',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
    },
    {
      name: 'fastTrackMeet',
      label: 'Meet',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'fastTrackMeet',
          id: 'id',
          value: 'date',
          parentField: 'fastTrackMeet'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'meetPosition',
      label: 'Meet Position',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'trackName',
      label: 'Track',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'raceNum',
      label: 'Race Num',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('fastTrackRace', fastTrackRaceDefinition);
