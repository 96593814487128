import React from 'react';
import { buildListQuery } from '../../entity/pages/entityQueryBuilder';
import SitePage from '../../site/SitePage';
import { SiteTable } from '../../table/SiteTable';
import { rankingDateSet } from '../../util/dateService';
import { RankingDatePicker } from '../rankings/RankingDatePicker';
import { rankingTabDefinitions } from '../rankings/RankingTabDefinitions';
import { trainerRankingDefinition } from './trainerRankingDefinition';

export function TrainerRankingList(props: {}) {
  const listQuery = buildListQuery(trainerRankingDefinition);
  const standardTableActions = {
    enableSearch: false,
    enableCalendarFilter: false,
    enableExport: true,
    enableColumnEdit: false,
    enableRefresh: true,
    enableCreate: false
  };
  const dateSet = rankingDateSet(new Date());
  const filterBy = {
    startDate: dateSet[0][0].start,
    endDate: dateSet[0][0].end
  };
  return (
    <SitePage title="Rankings" tabDefinitions={rankingTabDefinitions}>
      <SiteTable
        key={trainerRankingDefinition.name}
        entityDefinition={trainerRankingDefinition}
        query={listQuery}
        defaultRowPerPage={100}
        rowsPerPageOptions={[100]}
        standardTableActions={standardTableActions}
        filterOverrides={filterBy}
        customTableActions={[RankingDatePicker]}
        onEmpty="none"
      />
    </SitePage>
  );
}
