import { DatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { STANDARD_DATE_FORMAT } from '../util/dateService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core';

export type DateRangePickerProps = {
  onChange: (from: Date, to: Date) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

export function DateRangePicker(props: DateRangePickerProps) {
  const [fromDate, setFromDate] = useState(dayjs(new Date()).startOf('year').toDate());
  const [toDate, setToDate] = useState(dayjs(new Date()).endOf('year').toDate());
  const [open, setOpen] = React.useState(false);
  const { onChange, onOpen, onClose } = props;

  const handleClickOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = () => {
    onChange(fromDate, toDate);
    handleClose();
  };

  return (
    <>
      <MenuItem key="date-range-dialog-item" dense onClick={handleClickOpen}>
        Custom range
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="date-range-dialog"
        aria-describedby="date range dialog"
      >
        <DialogTitle id="date-range-dialog-title">Date Range</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <DatePicker
                key="date-range-picker-from"
                value={fromDate}
                fullWidth
                label="From"
                inputVariant="outlined"
                clearable
                format={STANDARD_DATE_FORMAT}
                onChange={(date) => {
                  if (date !== null && date !== undefined) {
                    setFromDate(date.toDate());
                  }
                }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                key="date-range-picker-to"
                value={toDate}
                fullWidth
                label="To"
                inputVariant="outlined"
                clearable
                format={STANDARD_DATE_FORMAT}
                onChange={(date) => {
                  if (date !== null && date !== undefined) {
                    setToDate(date.toDate());
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
