import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { USER_PROFILE_FRAGMENT, USER_PROFILE_QUERY } from './UserQueries';
import { DataZone } from '../zones/DataZone';
import { FormMessage } from '../form/FormMessage';
import { yupResolver } from '@hookform/resolvers/yup';

export interface MyUserProfileFormData {
  Name?: string;
  Email?: string;
}

const MY_PROFILE_UPDATE_MUTATION = gql`
  mutation MyProfileUpdate($input: MyProfileUpdateInput!) {
    updateMyProfile(input: $input) {
      user {
        ...UserProfile
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export default function MyProfileForm() {
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(USER_PROFILE_QUERY);
  const [updateUser, { loading: mutationLoading, error: mutationError }] = useMutation(MY_PROFILE_UPDATE_MUTATION);
  const validationSchema = yup.object().shape({
    Name: yup.string().required().max(50),
    Email: yup.string().required().email()
  });
  const { register, handleSubmit, errors } = useForm<MyUserProfileFormData>({
    resolver: yupResolver(validationSchema)
  });
  const user = queryData?.viewer.user;

  const onSubmit = async (formData: MyUserProfileFormData) => {
    if (user?.id) {
      try {
        await updateUser({ variables: { input: { id: user.id, name: formData.Name } } });
      } catch (error) {
        console.error('mutation error', error);
      }
    }
  };

  return (
    <DataZone loading={queryLoading} error={queryError}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader title="Details" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  name="Name"
                  defaultValue={user?.name}
                  fullWidth
                  required
                  label="Name"
                  variant="outlined"
                  inputRef={register}
                  error={errors.Name ? true : false}
                  helperText={errors.Name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="Email"
                  disabled
                  fullWidth
                  required
                  defaultValue={user?.email}
                  label="Email"
                  variant="outlined"
                  inputRef={register}
                  error={errors.Email ? true : false}
                  helperText={errors.Email?.message}
                />
              </Grid>
              <FormMessage message={mutationError ? mutationError.message : undefined} severity="error" />
            </Grid>
          </CardContent>
          <CardActions>
            <DataZone loading={queryLoading} loadingType="hidden">
              <Button
                disabled={mutationLoading}
                type="submit"
                aria-label="Save changes"
                variant="contained"
                color="secondary"
              >
                Save changes
              </Button>
            </DataZone>
          </CardActions>
        </Card>
      </form>
    </DataZone>
  );
}
