import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const fastTrackRaceDogDefinition: EntityDefinition = createEntityDefinition({
  name: 'fastTrackRaceDog',
  label: 'Fast Track Race Dog',
  pages: {
    list: {
      canCreate: false
    },
    view: {
      canEdit: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'dogName',
      label: 'Name',
      type: 'string',
      viewOptions: {
        selfLink: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      }
    },
    {
      name: 'fastTrackRace',
      label: 'Fast Track Race',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'fastTrackRace',
          id: 'id',
          value: 'raceName',
          parentField: 'fastTrackRace'
        }
      }
    },
    {
      name: 'place',
      label: 'Place',
      type: 'string'
    },
    {
      name: 'externalFastTrackRaceId',
      label: 'External Fast Track Race Id',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'externalFastTrackDogId',
      label: 'External Fast Track Dog Id',
      type: 'string',
      tableOptions: {
        visibleByDefault: false,
        sortable: true,
        canFilter: true
      }
    },
    {
      name: 'box',
      label: 'Box',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'checks',
      label: 'Checks',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'handicap',
      label: 'Handicap',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'margin1',
      label: 'Margin1',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'margin2',
      label: 'Margin2',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'pir',
      label: 'PIR',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'prizemoney',
      label: 'Prize money',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'rug',
      label: 'Rug',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'runTime',
      label: 'RunTime',
      type: 'string'
    },
    {
      name: 'splitMargin',
      label: 'Split Margin',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'startPrice',
      label: 'Start Price',
      type: 'string',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'trainer',
      label: 'Trainer',
      type: 'string'
    },
    {
      name: 'weight',
      label: 'Weight',
      type: 'string'
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('fastTrackRaceDog', fastTrackRaceDogDefinition);
