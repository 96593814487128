import { EntityDefinition } from '../entity/entityTypes';
import { Role } from '../graphql-types/globalTypes';
import { createEntityDefinition } from '../entity/entityHelper';
import { getEnumItemsInSentenceCase } from '../entity/entityEnumHelper';
import { registerEntityDefinition } from '../entity/entityDefinitionService';

export const userDefinition: EntityDefinition = createEntityDefinition({
  name: 'user',
  label: 'User',
  pages: {
    list: {
      canCreate: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        mandatory: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      type: 'string',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'role',
      label: 'Role',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getEnumItemsInSentenceCase(Object.values(Role))
        }
      },
      tableOptions: {
        canFilter: true
      },
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'createdAt',
      label: 'Created on',
      type: 'date',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Last Updated',
      type: 'date',
      tableOptions: {
        visibleByDefault: false,
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('user', userDefinition);
