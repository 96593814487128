/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface FastTrackRacesCreateInput {
  fastTrackMeetId: string;
}

export interface MeetsUpdateFromFastTrackInput {
  date: any;
}

export interface MyProfileUpdateInput {
  id: string;
  name: string;
}

export interface RaceCreateFromFastTrackInput {
  fastTrackRaceId: string;
}

export interface UserDeleteInput {
  id: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
