import { splitArray, stackArrays } from '../util/arrayHelper';
import { ColumnDirection, ColumnVisibility } from './tableTypes';
import { FieldDefinition } from '../entity/entityTypes';
import { getFieldForFieldDefinition, getFieldValueForEntityField } from '../entity/entityHelper';
import { formatToIsoDate } from '../util/dateService';
import { booleanToString, valueToSentenceCase } from '../util/stringService';

export function buildColumnVisibilities(
  columns: Array<FieldDefinition>,
  columnVisibilityOverrides: string[],
  isSmallScreen?: boolean,
  isExtraSmallScreen?: boolean
): Array<ColumnVisibility> {
  return columns
    .filter((field) => field.tableOptions.enabled)
    .map((column, index) => {
      let visibleOverride;
      if (index > 2 && isSmallScreen) visibleOverride = false;
      if (index > 0 && isExtraSmallScreen) visibleOverride = false;
      if (columnVisibilityOverrides.length > 0) {
        if (columnVisibilityOverrides.includes(column.name)) {
          visibleOverride = true;
        } else {
          visibleOverride = false;
        }
      }
      return {
        id: column.name,
        label: column.label,
        visible: visibleOverride ?? column.tableOptions.visibleByDefault
      };
    });
}

export function isColumnVisible(column: FieldDefinition, columnVisibilities: Array<ColumnVisibility>): boolean {
  return columnVisibilities.find((cv) => cv.id === column.name)?.visible ?? false;
}

export function filterInvisibleColumns(columns: Array<FieldDefinition>, columnVisibilities: Array<ColumnVisibility>) {
  return columns.filter((c) => isColumnVisible(c, columnVisibilities));
}

export function formatFieldForCsv(fieldDefinition: FieldDefinition, entity: any) {
  const value = getFieldForFieldDefinition(fieldDefinition, entity);
  switch (fieldDefinition.type) {
    case 'boolean':
      return booleanToString(value);
    case 'currency':
      return value;
    case 'date':
      return formatToIsoDate(value);
    case 'entity':
      return getFieldValueForEntityField(value, fieldDefinition);
    case 'enum':
      return valueToSentenceCase(value);
    case 'integer':
      return value;
    case 'float':
      return value;
    case 'string':
      return value;
    default:
      return value;
  }
}

export function formatDataAsCsv<EntityKind>(
  columns: Array<FieldDefinition>,
  rows: Array<EntityKind>,
  maxColumnLength?: number,
): { [key: string]: string }[] | string[][] {
  if (!rows) {
    return [];
  }
  const rowData = rows.map((node: any) => {
    const fields = columns.map((column: FieldDefinition) => {
      const value = formatFieldForCsv(column, node);
      const key = column.label;
      return { [key]: value };
    });

    return fields.reduce((ob, key) => {
      return {
        ...ob,
        ...key
      };
    }, {});
  });

  if(maxColumnLength !== undefined && rowData.length > maxColumnLength) {
    const splitData = splitArray(rowData, maxColumnLength);
    const objectToArrays: string[][][] = splitData.map((dataSet: object[][]) => dataSet.map((row: object[]) => Object.values(row)));
    return stackArrays(objectToArrays);
  }

  return rowData;
}

export function buildOrderByParts(orderBy: string) {
  const orderByParts = orderBy.split('_');
  const orderByColumn = orderByParts[0];
  const orderByDirection = orderByParts[1].toLowerCase() as ColumnDirection;
  return {
    orderBy,
    orderByParts,
    orderByColumn,
    orderByDirection
  };
}
