import React from 'react';
import SitePage from '../../site/SitePage';

interface Props {}

export function StakeEarnersListPage(props: Props) {
  return (
    <SitePage title="Top stake earners">
      <div>todo</div>
    </SitePage>
  );
}
