import React from 'react';
import { FieldProps } from '../../entity/entityTypes';
import { numberToString, NumberToStringOptions } from '../../util/stringService';
import { Typography } from '@material-ui/core';

export function formatGreyhoundCareer(entity: any): string {
  const { totalStarts, totalFirsts, totalSeconds, totalThirds } = entity;
  const zeroOption: NumberToStringOptions = { defaultTo: 'zero' };
  const startsString = numberToString(totalStarts, zeroOption);
  const firstsString = numberToString(totalFirsts, zeroOption);
  const secondsString = numberToString(totalSeconds, zeroOption);
  const thirdsString = numberToString(totalThirds, zeroOption);
  return `${startsString} Starts, ${firstsString}-${secondsString}-${thirdsString}`;
}

export function GreyhoundCareerViewField(props: FieldProps) {
  const { entity } = props;
  return <Typography>{formatGreyhoundCareer(entity)}</Typography>;
}
