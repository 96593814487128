import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SiteSideBarCategory } from './SiteSideBarCategory';

export interface SiteSideBarCategoryProps {
  label?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #404854',
    paddingBottom: theme.spacing(1)
  }
}));

export function SiteSideBarSection(props: SiteSideBarCategoryProps) {
  const { label, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.section}>
      {label ? <SiteSideBarCategory label={label} /> : null}
      {children}
    </div>
  );
}
