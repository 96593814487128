import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Tabs, Toolbar, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { NavTab } from '../navigation/NavTab';
import { TabDefinition } from './TabDefinition';

export interface Props {
  title: string;
  tabDefinitions?: TabDefinition[];
}

const useStyles = makeStyles((theme) => ({
  secondaryBar: {
    zIndex: 0
  }
}));

function SiteTitleHeader(props: Props) {
  const { title, tabDefinitions = [] } = props;
  const hasTabs = tabDefinitions.length > 0;

  const classes = useStyles();
  const location = useLocation();
  const selectedTab = tabDefinitions.findIndex((def) => def.route === location.pathname);
  return (
    <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      {hasTabs ? (
        <Tabs value={selectedTab} aria-label="navigation tabs">
          {tabDefinitions.map((tabDefinition) => {
            const { label, route } = tabDefinition;
            return <NavTab key={`${label}-${route}`} label={label} route={route} />;
          })}
        </Tabs>
      ) : null}
    </AppBar>
  );
}

export default SiteTitleHeader;
