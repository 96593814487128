import React from 'react';
import { HiddenZone } from './HiddenZone';
import { useAppContext } from '../ranker/AppContext';
import { Roles } from '../auth/authTypes';

interface Props {
  requiredRole: Roles;
  children?: React.ReactNode;
}

export function RoleZone(props: Props) {
  const { requiredRole, children } = props;
  const { context } = useAppContext();
  const user = context.user;
  const role = user?.role;
  const hasRole = role === requiredRole;
  return <HiddenZone show={hasRole}>{children}</HiddenZone>;
}

export function AdminZone({ children }: { children?: React.ReactNode }) {
  return RoleZone({ children, requiredRole: 'ADMIN' });
}

export function UserZone({ children }: { children?: React.ReactNode }) {
  return RoleZone({ children, requiredRole: 'USER' });
}
