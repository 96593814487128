import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { FilterChanges } from './tableTypes';
import useDebounce from '../util/useDebounce';
import { EntityDefinition } from '../entity/entityTypes';

interface Props {
  entityDefinition: EntityDefinition;
  updateFilters: (filterChanges: FilterChanges) => void;
}

const useStyles = makeStyles((theme) => ({
  search: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  searchBar: {
    height: 35
  }
}));

export function SiteTableSearchBar(props: Props) {
  const { entityDefinition, updateFilters } = props;
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  const searchInput: any = useRef(null);

  function handleFocus() {
    searchInput?.current?.focus();
  }

  const columns = entityDefinition.fields;
  const searchColumns = columns.filter((column) => column.type === 'string' && column.tableOptions.canFilter);
  const searchLabelString = searchColumns.map((searchColumn) => searchColumn.label.toLowerCase()).join(', ');
  const searchPlaceholder = searchLabelString ? `Search by ${searchLabelString}` : 'Search';

  // setup search and filter functions
  const debouncedSearchValue = useDebounce(searchValue, 250);

  useEffect(() => {
    if (searchColumns.length > 0) {
      const valueToFind = debouncedSearchValue;
      if (valueToFind) {
        const searchFilters = searchColumns.map((searchColumn) => {
          return { [`${searchColumn.name}_contains`]: valueToFind };
        });

        updateFilters({ added: { OR: searchFilters } });
      } else {
        updateFilters({ removed: { OR: null } });
      }
    }
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchValue('');
  };

  return (
    <TextField
      inputRef={searchInput}
      placeholder={searchPlaceholder}
      variant="outlined"
      size="small"
      InputProps={{
        classes: {
          root: classes.searchBar
        },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="start search" onClick={handleFocus} edge="start">
              <SearchIcon className={classes.search} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="clear search" onClick={handleSearchClear} edge="end">
              <ClearIcon className={classes.search} />
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={handleSearchChange}
      value={searchValue}
    />
  );
}
