import React from 'react';
import { FieldDefinition } from '../entityTypes';
import { EntityEditStringField } from './EntityEditStringField';
import { EntityEditEnumField } from './EntityEditEnumField';
import { EntityEditDateField } from './EntityEditDateField';
import { EntityEditBooleanField } from './EntityEditBooleanField';
import { EntityEditCurrencyField } from './EntityEditCurrencyField';
import { EntityEditIntegerField } from './EntityEditIntegerField';
import { EntityFindOrCreator } from './EntityFindOrCreator';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EntityEditFloatField } from './EntityEditFloatField';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
  formControl: any;
  watch: any;
  setValue: any;
}

export function EntityEditFieldForType(props: Props) {
  const { fieldDefinition, entity, formRef, formErrors, formControl } = props;
  switch (fieldDefinition.type) {
    case 'string':
      return (
        <EntityEditStringField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
        />
      );
    case 'date':
      return (
        <EntityEditDateField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'enum':
      return (
        <EntityEditEnumField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'currency':
      return (
        <EntityEditCurrencyField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'boolean':
      return (
        <EntityEditBooleanField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'integer':
      return (
        <EntityEditIntegerField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'float':
      return (
        <EntityEditFloatField
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    case 'entity':
      return (
        <EntityFindOrCreator
          fieldDefinition={fieldDefinition}
          entity={entity}
          formRef={formRef}
          formErrors={formErrors}
          formControl={formControl}
        />
      );
    default:
      return <></>;
  }
}

const useStyles = makeStyles((theme) => ({
  lineItem: {
    flexGrow: 1
  }
}));

export function EntityEditField(props: Props) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item className={classes.lineItem}>
        <EntityEditFieldForType {...props} />
      </Grid>
    </Grid>
  );
}
