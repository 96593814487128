import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  message?: string;
}

export function LoadingMessage(props: Props) {
  const { message } = props;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <CircularProgress size={16} />
      </Grid>
      {message ? (
        <Grid item>
          <Typography>{message}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}
