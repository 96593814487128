import { gql } from '@apollo/client';
import React from 'react';
import { FieldDefinition } from '../../entity/entityTypes';
import { MutationForm } from '../../form/MutationForm';
import { createFieldDefinition } from '../../entity/entityHelper';

interface Props {
  onFormSubmit: (result: any) => void
}

export const MEET_IMPORT_MUTATION = gql`
  mutation UpdateMeetsFromFastTrack($input: MeetsUpdateFromFastTrackInput!) {
    updateMeetsFromFastTrack(input: $input) {
      fastTrackMeets {
        id
        fastTrackTrack {
          name
        }
        date
        timeslot
      }
    }
  }
`;

export const meetingFields: FieldDefinition[] = [
  createFieldDefinition({
    name: 'date',
    label: 'Date',
    type: 'date',
    editOptions: {
      mandatory: true
    }
  })
];

export function RaceImportMeetsOnDateForm(props: Props) {
  const { onFormSubmit } = props;
  return (
    <MutationForm
      formTitle="Select date"
      fieldDefinitions={meetingFields}
      editQuery={MEET_IMPORT_MUTATION}
      submitLabel="Next"
      onFormSubmit={(result) => {
        onFormSubmit(result);
      }}
    />
  );
}
