import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FormMessage } from '../form/FormMessage';
import { EntityDefinition } from './entityTypes';
import {
  buildValidationSchemaForEntityDefinition,
  entityCreateNavTo,
  getNameTitle,
  parseFormDataForSubmission,
  buildEntityFromQueryParams
} from './entityHelper';
import { EntityEditField } from './editFields/EntityEditField';
import { ViewEntityListButton } from './actions/ViewEntityListButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingMessage } from '../loaders/LoadingMessage';
import { useQueryParams } from '../navigation/queryParamHelper';

interface Props {
  entityDefinition: EntityDefinition;
  createQuery: DocumentNode;
}

export function EntityCreateCard(props: Props) {
  const { createQuery, entityDefinition } = props;
  const history = useHistory();
  const queryParams = useQueryParams();
  const entity = buildEntityFromQueryParams(entityDefinition, queryParams);
  const title = `${getNameTitle(entityDefinition.name)}`;
  const fields = entityDefinition.fields;
  const [createEntity, { loading: mutationLoading, error: mutationError }] = useMutation(createQuery);
  const validationSchema = buildValidationSchemaForEntityDefinition(entityDefinition.fields);
  const { register, control, watch, setValue, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const [formMessage, setFormMessage] = useState<string | undefined>(undefined);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined);
  const apolloClient = useApolloClient();

  const onSubmit = async (formData: any) => {
    try {
      const parsedFormData = await parseFormDataForSubmission(apolloClient, entityDefinition.fields, formData);
      const { data } = await createEntity({ variables: { input: { id: entity.id, ...parsedFormData } } });
      setFormMessage('Create successful');
      const newEntity = data[`create${title}`][entityDefinition.name];
      if (newEntity) {
        const returnTo = queryParams.get('returnTo');
        entityCreateNavTo(entityDefinition, returnTo, newEntity, history);
      }
    } catch (error) {
      console.error('mutation error', error);
      setFormErrorMessage(error.message);
    }
  };

  const errorMessage = mutationError?.message ?? formErrorMessage;

  return (
    <Grid>
      <Grid item xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card>
            <CardHeader title={entityDefinition.label} />
            <CardContent>
              <Grid container spacing={3}>
                {fields
                  .filter((field) => field.editOptions.enabled)
                  .map((field) => {
                    return (
                      <Grid item xs={12} key={field.name}>
                        <EntityEditField
                          fieldDefinition={field}
                          entity={entity}
                          formRef={register}
                          formErrors={errors}
                          formControl={control}
                          watch={watch}
                          setValue={setValue}
                        />
                      </Grid>
                    );
                  })}
                <FormMessage message={errorMessage} severity="error" />
                <FormMessage message={formMessage} severity="success" />
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Button
                    disabled={mutationLoading}
                    type="submit"
                    aria-label="Create"
                    variant="contained"
                    color="secondary"
                  >
                    Create
                  </Button>
                </Grid>
                <Grid item>
                  <ViewEntityListButton entityDefinition={entityDefinition} />
                </Grid>
                {mutationLoading ? (
                  <Grid item>
                    <LoadingMessage message={'Creating...'} />
                  </Grid>
                ) : null}
              </Grid>
            </CardActions>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
}
