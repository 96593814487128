import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SitePage from '../../site/SitePage';
import { sortByName } from '../../util/objectHelper';
import { raceDefinition } from './raceDefinition';
import { RaceImportMeetsOnDateForm } from './RaceImportMeetsOnDateForm';
import { RaceImportMeetSelectorForm } from './RaceImportMeetSelectorForm';
import { getEntityNavTarget } from '../../entity/entityHelper';
import { RaceImportRaceSelectorForm } from './RaceImportRaceSelectorForm';

interface Props {}

export function RaceImportPage(props: Props) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [meets, setMeets] = useState<any[]>([]);
  const [fastTrackRaces, setFastTrackRaces] = useState<any[]>([]);

  const onDateSelect = (result: any) => {
    const foundFastTrackMeets = result?.data?.updateMeetsFromFastTrack?.fastTrackMeets ?? [];
    const meets = foundFastTrackMeets
      .map((ftm: any) => {
        return {
          id: ftm.id,
          name: ftm.fastTrackTrack.name,
          date: ftm.date,
          timeslot: ftm.timeslot
        };
      })
      .sort(sortByName);
    setMeets(meets);
    setActiveStep(1);
  };

  const onMeetSelectBack = () => {
    setMeets([]);
    setActiveStep(0);
  };

  const onMeetSelect = (result: any) => {
    const foundFastTrackRaces = result?.data?.createFastTrackRaces?.races ?? [];
    setFastTrackRaces(foundFastTrackRaces);
    setActiveStep(2);
  };

  const onRaceSelectBack = () => {
    setFastTrackRaces([]);
    setActiveStep(1);
  };

  const onRaceSelect = (race: any) => {
    const loc = getEntityNavTarget(raceDefinition, 'view', race);
    history.push(loc);
  };

  return (
    <SitePage title="Import Race">
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select date</StepLabel>
          <StepContent>
            <RaceImportMeetsOnDateForm onFormSubmit={onDateSelect} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Select track</StepLabel>
          <StepContent>
            <RaceImportMeetSelectorForm meets={meets} onFormSubmit={onMeetSelect} onFormCancel={onMeetSelectBack} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Select race</StepLabel>
          <StepContent>
            <RaceImportRaceSelectorForm fastTrackRaces={fastTrackRaces} onFormSubmit={onRaceSelect} onFormCancel={onRaceSelectBack} />
          </StepContent>
        </Step>
      </Stepper>
    </SitePage>
  );
}
