import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

export interface SiteSideBarItemProps {
  variant?: 'primary' | 'standard' | 'logo';
  label: string;
  goToPage?: (pathName: string) => void;
  pathName?: string;
  children?: React.ReactNode;
  href?: string;
  matchingPaths?: string[];
}

const useStyles = makeStyles((theme) => ({
  itemTextPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize
  },
  itemTextStandard: {
    fontWeight: 400
  },
  item: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,

    color: theme.palette.primary.contrastText
  },
  itemPrimary: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    color: theme.palette.primary.contrastText
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  itemActiveItem: {
    color: theme.palette.secondary.main
  },
  itemCategory: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16
  },
  logoListItem: {
    paddingTop: 4
  },
  logoText: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white
  }
}));

function SiteSideBarItem(props: SiteSideBarItemProps) {
  const { label, goToPage, pathName, children, variant, href, matchingPaths = [] } = props;
  const classes = useStyles();
  const location = useLocation();
  const activeClass =
    location.pathname === pathName || matchingPaths.includes(location.pathname) ? classes.itemActiveItem : '';
  const logoClasses = `${classes.logoListItem} ${classes.item}`;
  const primaryClasses = `${classes.itemPrimary} ${classes.itemActionable} ${activeClass}`;
  const standardClasses = `${classes.item} ${classes.itemActionable} ${activeClass}`;
  let textClasses = {
    primary: classes.itemTextStandard
  };
  let itemClasses = standardClasses;
  if (variant === 'primary') {
    itemClasses = primaryClasses;
    textClasses = {
      primary: classes.itemTextPrimary
    };
  }
  if (variant === 'logo') {
    itemClasses = logoClasses;
    textClasses = {
      primary: classes.logoText
    };
  }

  if (href) {
    return (
      <ListItem button dense className={itemClasses} component="a" href={href}>
        {children ? <ListItemIcon className={classes.itemTextStandard}>{children}</ListItemIcon> : null}
        <ListItemText classes={textClasses}>{label}</ListItemText>
      </ListItem>
    );
  }
  if (goToPage && pathName) {
    return (
      <ListItem button dense className={itemClasses} onClick={() => goToPage(pathName)}>
        {children ? <ListItemIcon className={classes.itemTextStandard}>{children}</ListItemIcon> : null}
        <ListItemText classes={textClasses}>{label}</ListItemText>
      </ListItem>
    );
  }
  return null;
}

export default SiteSideBarItem;
