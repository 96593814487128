import React from 'react';
import Radio from '@material-ui/core/Radio';
import { RadioGroup, FormLabel, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { FormFieldDefinition } from './FormFieldDefinition';

interface RadioListFieldProps {
  fieldDefinition: FormFieldDefinition;
  formControl: any;
  formErrors: any;
}

export function RadioListField(props: RadioListFieldProps) {
  const { fieldDefinition, formControl, formErrors } = props;
  const name = fieldDefinition.name;
  const label = fieldDefinition.label;
  const options = fieldDefinition.typeOptions?.radioList?.options ?? [];
  return (
    <FormControl component="fieldset" error={formErrors[fieldDefinition.name] != null}>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        as={
          <RadioGroup aria-label={label}>
            {options.map((option) => {
              return <FormControlLabel key={option.id} value={option.id} label={option.name} control={<Radio />} />;
            })}
          </RadioGroup>
        }
        name={name}
        defaultValue={null}
        control={formControl}
      />
      <FormHelperText>{formErrors[fieldDefinition.name]?.message}</FormHelperText>
    </FormControl>
  );
}
