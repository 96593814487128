import React from 'react';
import SitePage from '../../site/SitePage';
import { EntityDefinition } from '../entityTypes';
import { buildDeleteQuery, buildViewQuery } from './entityQueryBuilder';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DataZone } from '../../zones/DataZone';
import { EntityViewCard } from '../cards/EntityViewCard';
import { EntityChildListCard } from '../EntityChildListCard';

export interface Props {
  entityDefinition: EntityDefinition;
  singleColumn?: boolean;
}

export function EntityViewPage(props: Props) {
  const { entityDefinition, singleColumn = false } = props;
  const childLists = entityDefinition.pages.view.childLists;
  let { id } = useParams();
  const viewQuery = buildViewQuery(entityDefinition);
  const deleteQuery = buildDeleteQuery(entityDefinition);
  const { loading, error, data } = useQuery(viewQuery, {
    variables: { id }
  });
  const cardFlex = singleColumn ? 12 : 6;
  const entity = data && data[entityDefinition.name] ? data[entityDefinition.name] : {};
  return (
    <SitePage title={entityDefinition.label}>
      <DataZone loadingType="spinner" loading={loading} error={error}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={cardFlex}>
            <EntityViewCard entityDefinition={entityDefinition} entity={entity} deleteQuery={deleteQuery} />
          </Grid>
          {childLists.map((childListDefinition) => {
            return (
              <Grid key={`${entityDefinition.name}-${childListDefinition.name}`} item xs={12} md={6}>
                <EntityChildListCard
                  entityDefinition={entityDefinition}
                  entity={entity}
                  childListDefinition={childListDefinition}
                />
              </Grid>
            );
          })}
        </Grid>
      </DataZone>
    </SitePage>
  );
}
