import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const trainerRankingDefinition: EntityDefinition = createEntityDefinition({
  name: 'trainerRanking',
  label: 'Trainer Rankings',
  pages: {
    list: {
      orderBy: 'points_DESC'
    },
    view: {
      enabled: false
    },
    create: {
      enabled: false
    },
    edit: {
      enabled: false
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'ranking',
      label: 'Rank',
      type: 'integer',
      tableOptions: {
        sortable: true,
        maxLength: 2
      }
    },
    {
      name: 'trainer',
      label: 'Trainer',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'trainerId'
        }
      }
    },
    {
      name: 'points',
      label: 'Points',
      type: 'integer',
      tableOptions: {
        sortable: true
      }
    }
  ]
});

registerEntityDefinition('trainer_ranking', trainerRankingDefinition);
