import React from 'react';
import numeral from 'numeral';
import { EntityViewProps } from './EntityViewProps';
import { Typography } from '@material-ui/core';

export function formatInteger(value: number | undefined | null): string {
  if (value === undefined || value === null) {
    return '';
  }
  return numeral(value).format('0');
}

export function EntityViewIntegerField(props: EntityViewProps) {
  const { value } = props;
  return <Typography>{formatInteger(value)}</Typography>;
}
