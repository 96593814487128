import React from 'react';
import { FieldDefinition } from '../entityTypes';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
  formControl: any;
}

export function EntityEditBooleanField(props: Props) {
  const { fieldDefinition, entity, formRef, formErrors } = props;
  return (
    <FormControl required error={formErrors[fieldDefinition.name] != null} component="fieldset">
      <FormControlLabel
        label={fieldDefinition.label}
        control={
          <Checkbox inputRef={formRef} name={fieldDefinition.name} defaultChecked={entity[fieldDefinition.name]} />
        }
      />
      <FormHelperText>{formErrors[fieldDefinition.name]?.message}</FormHelperText>
    </FormControl>
  );
}
