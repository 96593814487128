import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { BasicForm } from '../../form/BasicForm';
import { FormFieldDefinition } from '../../form/FormFieldDefinition';
import {CreateRaceFromFastTrack, CreateRaceFromFastTrackVariables} from "../../graphql-types/CreateRaceFromFastTrack";
import { arrayToString } from '../../util/stringService';
import { DataZone } from '../../zones/DataZone';

interface Props {
  onFormSubmit: (result: any) => void;
  onFormCancel: () => void;
  fastTrackRaces: any[];
}

export const RACE_IMPORT_MUTATION = gql`
  mutation CreateRaceFromFastTrack($input: RaceCreateFromFastTrackInput!) {
    createRaceFromFastTrack(input: $input) {
      race {
        id
      }
    }
  }
`;

export function RaceImportRaceSelectorForm(props: Props) {
  const { onFormSubmit, onFormCancel, fastTrackRaces } = props;
  const [raceImport, { loading: mutationLoading, error: mutationError }] = useMutation<CreateRaceFromFastTrack, CreateRaceFromFastTrackVariables>(RACE_IMPORT_MUTATION);

  if (fastTrackRaces.length === 0) {
    return <div>No races found for this meet</div>;
  }

  const fields: FormFieldDefinition[] = [
    {
      name: 'race',
      label: '',
      type: 'radioList',
      mandatory: true,
      enabled: true,
      typeOptions: {
        radioList: {
          options: fastTrackRaces.map((fastTrackRace) => {
            const raceNumber = fastTrackRace.raceNum ? `Race ${fastTrackRace.raceNum}` : '';
            const name = arrayToString([raceNumber, fastTrackRace.raceName, fastTrackRace.raceTime], ' - ');
            return {
              id: fastTrackRace.id,
              name
            };
          })
        }
      }
    }
  ];

  const onRaceSelect = async (formData: any) => {
    const fastTrackRaceId = formData.race;
    const result = await raceImport({ variables: { input: { fastTrackRaceId } } });

    onFormSubmit(result.data?.createRaceFromFastTrack.race);
  };

  return (
    <DataZone loading={mutationLoading} error={mutationError} loadingType="spinner" errorType="errorMessage">
      <BasicForm fields={fields} submitLabel="Import" onFormSubmit={onRaceSelect} cancelLabel="Back" onFormCancel={onFormCancel} />
    </DataZone>
  );
}
