import { createMuiTheme } from '@material-ui/core';

export let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#293b4b',
      main: '#001523',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#a2f177',
      main: '#6fbe47',
      dark: '#3c8d14',
      contrastText: '#000000'
    }
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.primary.main
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: theme.palette.primary.light
      }
    },
    MuiButton: {
      label: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1)
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        '&:hover': {
          color: theme.palette.common.white,
          opacity: 1
        },
        '&$selected': {
          color: theme.palette.secondary.main
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 0
        }
      },
      selected: {}
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1)
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiListItem: {
      button: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        minWidth: 40,
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};
