import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { getEnumItemsInSentenceCase } from '../../entity/entityEnumHelper';
import { TrackStatus } from './trackEnums';

export const trackDefinition: EntityDefinition = createEntityDefinition({
  name: 'track',
  label: 'Track',
  pages: {
    list: {
      orderBy: 'name_ASC'
    },
    view: {
      childLists: [
        {
          name: 'races',
          childField: 'track',
          title: 'Races',
          type: 'race',
          filterBy: [{ childFilter: 'trackId_equal', entityField: 'id' }],
          overrideFields: ['name', 'date']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      },
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'trackStatus',
      label: 'Status',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getEnumItemsInSentenceCase(Object.values(TrackStatus))
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('track', trackDefinition);
