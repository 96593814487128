import React, { useState } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import { formatDateRange, getDateRangeLabel, getDateSet } from '../util/dateService';
import { DateRangePicker } from '../date/DateRangePicker';

interface Props {
  onDateRangeChange: (from?: Date, to?: Date) => void;
}

export function SiteTableDatePickerMenu(props: Props) {
  const { onDateRangeChange } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dateLabel, setDateLabel] = useState('All time');

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const updateDateRange = (label: string, from?: Date, to?: Date) => {
    onDateRangeChange(from, to);
    setDateLabel(getDateRangeLabel(from, to));
    closeMenu();
  };

  const dateSet = getDateSet(new Date());

  return (
    <>
      <Button
        aria-label="open date menu"
        aria-describedby="date-options"
        aria-controls="date-options"
        aria-haspopup="true"
        startIcon={<DateRangeIcon />}
        onClick={openMenu}
      >
        {dateLabel}
      </Button>
      <Menu id="date-options" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
        <MenuItem key="all_time" dense onClick={() => updateDateRange('All time', undefined, undefined)}>
          All time
        </MenuItem>
        {dateSet.map((ds, index) => {
          return (
            <div key={index}>
              <Divider />
              {ds.map((dss) => {
                return (
                  <MenuItem key={dss.label} dense onClick={() => updateDateRange(dss.label, dss.start, dss.end)}>
                    {dss.label}
                  </MenuItem>
                );
              })}
            </div>
          );
        })}
        <Divider />
        <DateRangePicker
          onOpen={() => closeMenu()}
          onChange={(from, to) => {
            updateDateRange(formatDateRange(from, to), from, to);
          }}
        />
      </Menu>
    </>
  );
}
