export function splitArray(items: any[], perChunk: number) {
  return items.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
}

export function stackArrays(items: string[][][]) {
  return items[0].reduce((all, one, i) => {
    all[i] = items.reduce((extra, extraOne, j) => {
      return extra.concat(extraOne[i]);
    }, [] as string[]);
    return all;
  }, [] as string[][]);
}
