import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { GreyhoundCareerViewField } from './GreyhoundCareerViewField';
import { getEnumItemsInSentenceCase, getStateEnumItems, Gender } from '../../entity/entityEnumHelper';
import { SyncGreyhoundButton } from '../fastTrackDog/SyncGreyhoundButton';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';

export const greyhoundDefinition: EntityDefinition = createEntityDefinition({
  name: 'greyhound',
  label: 'Greyhound',
  pages: {
    view: {
      actions: [SyncGreyhoundButton],
      childLists: [
        {
          name: 'races',
          childField: 'greyhound',
          title: 'Results',
          type: 'raceField',
          filterBy: [{ childFilter: 'greyhoundId_equal', entityField: 'id' }],
          orderBy: 'place_ASC',
          overridePageSize: 10,
          overrideFields: ['place', 'race', 'date', 'score']
        },
        {
          name: 'offspring',
          childField: 'sire',
          title: 'Offspring',
          type: 'greyhound',
          filterBy: {
            OR: [
              { childFilter: 'sireId_equal', entityField: 'id' },
              { childFilter: 'damId_equal', entityField: 'id' }
            ]
          },
          overrideFields: ['name', 'career', 'totalPrizeMoney']
        }
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      },
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'gender',
      label: 'Sex',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getEnumItemsInSentenceCase(Object.values(Gender))
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'color',
      label: 'Color',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'state',
      label: 'State',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getStateEnumItems()
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'dateOfBirth',
      label: 'Whelped',
      type: 'date',
      tableOptions: {
        visibleByDefault: false
      },
      typeOptions: {
        date: {
          max: 'now'
        }
      }
    },

    {
      name: 'totalPrizeMoney',
      label: 'Prize money',
      type: 'currency',
      tableOptions: {
        visibleByDefault: false,
        sortable: true,
        canFilter: true
      }
    },
    {
      name: 'career',
      label: 'Career',
      type: 'custom',
      typeOptions: {
        custom: {
          component: GreyhoundCareerViewField
        }
      },
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'totalStarts',
      label: 'Starts',
      type: 'integer',
      tableOptions: {
        visibleByDefault: false
      },
      viewOptions: {
        enabled: false
      }
    },
    {
      name: 'totalFirsts',
      label: 'Firsts',
      type: 'integer',
      tableOptions: {
        visibleByDefault: false
      },
      viewOptions: {
        enabled: false
      }
    },
    {
      name: 'totalSeconds',
      label: 'Seconds',
      type: 'integer',
      tableOptions: {
        visibleByDefault: false
      },
      viewOptions: {
        enabled: false
      }
    },
    {
      name: 'totalThirds',
      label: 'Thirds',
      type: 'integer',
      tableOptions: {
        visibleByDefault: false
      },
      viewOptions: {
        enabled: false
      }
    },
    {
      name: 'dateOfLastRace',
      label: 'Last raced',
      type: 'date',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'retired',
      label: 'Retired',
      type: 'boolean',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'sire',
      label: 'Sire',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'greyhound',
          id: 'id',
          value: 'name',
          parentField: 'sireId'
        },
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'dam',
      label: 'Dam',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'greyhound',
          id: 'id',
          value: 'name',
          parentField: 'damId'
        },
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'ownerId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'trainer',
      label: 'Trainer',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'trainerId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'breeder',
      label: 'Breeder',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'breederId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('greyhound', greyhoundDefinition);
