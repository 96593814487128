import React from 'react';
import { FieldDefinition } from '../entityTypes';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formErrors: any;
}

const useStyles = makeStyles((theme) => ({
  input: ({ changeCase }: any) => ({
    textTransform: changeCase
  })
}));

export function EntityEditStringField(props: Props) {
  const { fieldDefinition, entity, formRef, formErrors } = props;
  const changeCase = fieldDefinition?.typeOptions.string?.changeCase ?? 'none';
  const classes = useStyles({ changeCase });
  return (
    <TextField
      name={fieldDefinition.name}
      defaultValue={entity[fieldDefinition.name]}
      fullWidth
      data-lpignore="true"
      required={fieldDefinition.editOptions.mandatory}
      label={fieldDefinition.label}
      variant="outlined"
      inputRef={formRef}
      InputProps={{
        classes,
        inputProps: {
          'aria-autocomplete': 'none',
          'data-lpignore': 'true'
        }
      }}
      error={formErrors[fieldDefinition.name] != null}
      helperText={formErrors[fieldDefinition.name]?.message}
    />
  );
}
