import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { getStateEnumItems } from '../../entity/entityEnumHelper';
import { RaceFieldBoxField } from './RaceFieldBoxField';
import { RaceFieldPlaceField } from './RaceFieldPlaceField';

export const raceFieldDefinition: EntityDefinition = createEntityDefinition({
  name: 'raceField',
  label: 'Race Field',
  pages: {
    view: {
      enabledForRole: 'ADMIN',
      childLists: [
        {
          name: 'scores',
          childField: 'raceField',
          title: 'Points',
          type: 'score',
          filterBy: [{ childFilter: 'raceFieldId_equal', entityField: 'id' }],
          overrideFields: ['amount', 'system']
        },
      ]
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'place',
      label: 'Placing',
      type: 'integer',
      viewOptions: {
        selfLink: true,
        component: RaceFieldPlaceField
      }
    },
    {
      name: 'race',
      label: 'Race',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'race',
          id: 'id',
          value: 'name',
          parentField: 'raceId'
        },
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'race',
      label: 'Date',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'race',
          id: 'id',
          value: 'date',
          valueType: 'date',
          linkTo: false,
          parentField: 'raceId'
        },
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'greyhound',
      label: 'Greyhound',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'greyhound',
          id: 'id',
          value: 'name',
          parentField: 'greyhoundId'
        },
        string: {
          changeCase: 'uppercase'
        }
      }
    },
    {
      name: 'score',
      label: 'Points',
      type: 'entity',
      fieldArgs: [{ name: 'system', value: 'GREYHOUND'}],
      typeOptions: {
        entity: {
          type: 'score',
          id: 'id',
          value: 'amount',
          parentField: 'raceFieldId'
        }
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'boxNumber',
      label: 'Box',
      type: 'integer',
      viewOptions: {
        selfLink: true,
        component: RaceFieldBoxField
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'prizeMoney',
      label: 'Prize money',
      type: 'currency',
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'timeInSeconds',
      label: 'Time',
      type: 'float',
      typeOptions: {
        float: {
          suffix: 'seconds'
        }
      }
    },
    {
      name: 'marginFromWinner',
      label: 'Margin from winner',
      type: 'float',
      typeOptions: {
        float: {
          suffix: 'lengths'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'marginFromNextPlace',
      label: 'Margin next place',
      type: 'float',
      typeOptions: {
        float: {
          suffix: 'lengths'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'disqualified',
      label: 'Disqualified',
      type: 'boolean',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'didNotFinish',
      label: 'Did not finish',
      type: 'boolean',
      tableOptions: {
        visibleByDefault: false
      },
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'ownerId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'trainer',
      label: 'Trainer',
      type: 'entity',
      typeOptions: {
        entity: {
          type: 'person',
          id: 'id',
          value: 'name',
          parentField: 'trainerId'
        },
        string: {
          changeCase: 'uppercase'
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'state',
      label: 'State',
      type: 'enum',
      typeOptions: {
        enum: {
          items: getStateEnumItems()
        }
      },
      tableOptions: {
        visibleByDefault: false
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('raceField', raceFieldDefinition);
