import { EntityDefinition } from "../entity/entityTypes";
import { createEntityDefinition } from "../entity/entityHelper";
import { registerEntityDefinition } from "../entity/entityDefinitionService";

export const externalSystemDefinition: EntityDefinition = createEntityDefinition({
  name: 'externalSystem',
  label: 'External System',
  pages: {

  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('externalSystem', externalSystemDefinition);
