import React from 'react';
import { FieldDefinition, FieldDefinitionEnumTypeItem } from '../entityTypes';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getEnumItemForField } from '../entityEnumHelper';

interface Props {
  fieldDefinition: FieldDefinition;
  entity: any;
  formRef: any;
  formControl: any;
  formErrors: any;
}

export function EntityEditEnumField(props: Props) {
  const { fieldDefinition, entity, formControl } = props;
  const fieldValue = entity[fieldDefinition.name];
  const items = fieldDefinition.typeOptions.enum?.items ?? [];
  const defaultValue = getEnumItemForField(fieldDefinition, fieldValue);
  return (
    <Controller
      control={formControl}
      name={fieldDefinition.name}
      defaultValue={defaultValue}
      render={(controllerProps) => (
        <Autocomplete
          {...controllerProps}
          selectOnFocus
          openOnFocus
          id={`enum-select-label-${fieldDefinition.name}`}
          options={items}
          getOptionLabel={(option: FieldDefinitionEnumTypeItem) => option.label}
          onChange={(event, newValue) => {
            controllerProps.onChange(newValue);
          }}
          renderInput={(params) => {
            (params as any).inputProps['data-lpignore'] = 'true';
            return <TextField {...params} label={fieldDefinition.label} variant="outlined" />;
          }}
        />
      )}
    />
  );
}
