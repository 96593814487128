import React from 'react';
import { Card, Grid, CardHeader, Divider, Table, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import { DocumentNode } from '@apollo/client';
import { EntityDefinition } from '../entityTypes';
import { TextTableLabel } from '../TextTableLabel';
import { EntityViewField } from '../viewFields/EntityViewField';
import { AdminZone } from '../../zones/RoleZone';
import { HiddenZone } from '../../zones/HiddenZone';
import { EditEntityButton } from '../actions/EditEntityButton';
import { ViewEntityListButton } from '../actions/ViewEntityListButton';
import { DeleteEntityButton } from '../actions/DeleteEntityButton';

interface Props {
  entity: any;
  entityDefinition: EntityDefinition;
  deleteQuery?: DocumentNode;
}

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2)
  }
}));

export function EntityViewCard(props: Props) {
  const classes = useStyles();
  const { entityDefinition, entity, deleteQuery } = props;
  const fields = entityDefinition.fields;

  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <Table>
        <TableBody>
          {fields
            .filter((field) => field.viewOptions.enabled)
            .map((field) => {
              return (
                <TableRow key={`${field.name}-${field.label}`}>
                  <TableCell style={{ width: '25%' }}>
                    <TextTableLabel>{field.label}</TextTableLabel>
                  </TableCell>
                  <TableCell>
                    <EntityViewField fieldDefinition={field} entity={entity} entityDefinition={entityDefinition} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Grid className={classes.actions} container spacing={1}>
        <AdminZone>
          <HiddenZone show={entityDefinition.pages.view.canEdit}>
            <Grid item>
              <EditEntityButton entityDefinition={entityDefinition} entityId={entity?.id} />
            </Grid>
          </HiddenZone>
          {entityDefinition.pages.view.actions.map((ActionComp, i) => {
            return (
              <Grid item key={i}>
                <ActionComp entityDefinition={entityDefinition} entity={entity} />
              </Grid>
            );
          })}
          {deleteQuery && entityDefinition.pages.view.canDelete ? (
            <Grid item>
              <DeleteEntityButton entityId={entity?.id} entityDefinition={entityDefinition} mutation={deleteQuery} />
            </Grid>
          ) : null}
        </AdminZone>
        <Grid item>
          <ViewEntityListButton entityDefinition={entityDefinition} />
        </Grid>
      </Grid>
    </Card>
  );
}
