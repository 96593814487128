import React from 'react';

interface Props {
  show?: boolean;
  children?: React.ReactNode;
}

export function HiddenZone(props: Props) {
  const { show = false, children } = props;

  if (!show || !children) {
    return null;
  }

  return <>{children}</>;
}
