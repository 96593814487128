import React from 'react';
import { Drawer, List } from '@material-ui/core';
import IconLogo from '../ranker/icons/iconLogo.png';
import RankingIcon from '../ranker/icons/RankingIcon';
import GreyhoundIcon from '../ranker/icons/GreyhoundIcon';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import StoreIcon from '@material-ui/icons/Store';
import EventIcon from '@material-ui/icons/Event';
import ListIcon from '@material-ui/icons/List';
import GradeIcon from '@material-ui/icons/Grade';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ScoreIcon from '@material-ui/icons/Score';
import { makeStyles } from '@material-ui/core/styles';
import SiteSideBarItem from './SiteSideBarItem';
import { useHistory } from 'react-router-dom';
import { AdminZone } from '../zones/RoleZone';
import { FeatureZone } from '../zones/FeatureZone';
import { SiteSideBarSection } from './SiteSideBarSection';
import { logout } from '../auth/authHelper';
import { useAppContext } from '../ranker/AppContext';
import SyncIcon from '@material-ui/icons/Sync';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallMadeIcon from '@material-ui/icons/CallMade';
import FeedbackIcon from '@material-ui/icons/Feedback';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

export interface SiteSideBarProps {
  variant: 'permanent' | 'temporary';
  open?: boolean;
  close?: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  logoImage: {
    width: '20px',
    height: '20px'
  }
}));

function SiteSideBar(props: SiteSideBarProps) {
  const { variant, open, onClose, close } = props;
  const history = useHistory();
  const classes = useStyles();
  const { context } = useAppContext();
  const { isAuthenticated = false } = context;

  const goToPage = async (location: string) => {
    if (close) {
      close();
    }
    history.push(location);
  };
  const goToLogout = () => {
    logout();
  };

  return (
    <Drawer variant={variant} PaperProps={{ style: { width: 256 } }} open={open} onClose={onClose}>
      <List disablePadding>
        <SiteSideBarItem variant="logo" label="Ranker" goToPage={goToPage} pathName="/">
          <img src={IconLogo} alt="logo" className={classes.logoImage} />
        </SiteSideBarItem>
        <SiteSideBarSection label="Records">
          <SiteSideBarItem
            label="Rankings"
            goToPage={goToPage}
            pathName="/rankings"
            matchingPaths={['/rankings/greyhound', '/rankings/sire', '/rankings/dam', '/rankings/trainer']}
          >
            <RankingIcon />
          </SiteSideBarItem>
          <FeatureZone>
            <SiteSideBarItem label="Top stake earners" goToPage={goToPage} pathName="/earning-rankings">
              <AttachMoneyIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Track records" goToPage={goToPage} pathName="/track-records">
              <TimerIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Group race results" goToPage={goToPage} pathName="/group-race-results">
              <ListIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Honour rolls" goToPage={goToPage} pathName="/honour-rolls">
              <GradeIcon />
            </SiteSideBarItem>
          </FeatureZone>
        </SiteSideBarSection>
        <SiteSideBarSection label="Data">
          <SiteSideBarItem label="Greyhounds" goToPage={goToPage} pathName="/greyhounds">
            <GreyhoundIcon />
          </SiteSideBarItem>
          <SiteSideBarItem label="Races" goToPage={goToPage} pathName="/races">
            <AssessmentIcon />
          </SiteSideBarItem>
          <SiteSideBarItem label="Meets" goToPage={goToPage} pathName="/meets">
            <EventNoteIcon />
          </SiteSideBarItem>
          <SiteSideBarItem label="Owners and Trainers" goToPage={goToPage} pathName="/persons">
            <GroupIcon />
          </SiteSideBarItem>
          <SiteSideBarItem label="Tracks" goToPage={goToPage} pathName="/tracks">
            <AllInclusiveIcon />
          </SiteSideBarItem>
          <SiteSideBarItem label="Clubs" goToPage={goToPage} pathName="/clubs">
            <StoreIcon />
          </SiteSideBarItem>
        </SiteSideBarSection>
        <AdminZone>
          <SiteSideBarSection label="System Data">
            <SiteSideBarItem label="Fields" goToPage={goToPage} pathName="/race-fields">
              <AssignmentIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Scores" goToPage={goToPage} pathName="/scores">
              <ScoreIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Feature Groups" goToPage={goToPage} pathName="/feature-groups">
              <EventIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="External Systems" goToPage={goToPage} pathName="/external-systems">
              <QueuePlayNextIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="External References" goToPage={goToPage} pathName="/external-references">
              <LowPriorityIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Import References" goToPage={goToPage} pathName="/import-references">
              <PlaylistAddCheckIcon />
            </SiteSideBarItem>
          </SiteSideBarSection>
          <SiteSideBarSection label="Fast Track Data">
            <SiteSideBarItem label="Fast Track Dogs" goToPage={goToPage} pathName="/fast-track-dogs">
              <SyncIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Fast Track Meets" goToPage={goToPage} pathName="/fast-track-meets">
              <CallMissedOutgoingIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Fast Track Tracks" goToPage={goToPage} pathName="/fast-track-tracks">
              <CallMadeIcon />
            </SiteSideBarItem>
              <SiteSideBarItem label="Fast Track Races" goToPage={goToPage} pathName="/fast-track-races">
                <CallReceivedIcon />
              </SiteSideBarItem>
            <SiteSideBarItem label="Fast Track Race Dogs" goToPage={goToPage} pathName="/fast-track-race-dogs">
              <ArrowDownwardIcon />
            </SiteSideBarItem>
          </SiteSideBarSection>
        </AdminZone>
        <AdminZone>
          <SiteSideBarSection label="Admin">
            <SiteSideBarItem label="Users" goToPage={goToPage} pathName="/users">
              <SupervisorAccountIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Settings" goToPage={goToPage} pathName="/settings">
              <SettingsIcon />
            </SiteSideBarItem>
          </SiteSideBarSection>
        </AdminZone>
        {isAuthenticated && variant === 'temporary' ? (
          <SiteSideBarSection>
            <SiteSideBarItem label="Profile" goToPage={goToPage} pathName="/my-profile">
              <AccountCircleIcon />
            </SiteSideBarItem>
            <SiteSideBarItem label="Logout" goToPage={goToLogout} pathName="/logout">
              <ArrowBackIcon />
            </SiteSideBarItem>
          </SiteSideBarSection>
        ) : null}
        <SiteSideBarSection>
          <SiteSideBarItem label="Send feedback" href="https://scottdb.atlassian.net/servicedesk/customer/portal/3">
            <FeedbackIcon />
          </SiteSideBarItem>
        </SiteSideBarSection>
      </List>
    </Drawer>
  );
}

export default SiteSideBar;
