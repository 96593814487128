import { Grid } from '@material-ui/core';
import React from 'react';
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';
import { FieldProps } from '../entityTypes';

export function EntityViewCodeField(props: FieldProps) {
  const { value, fieldDefinition } = props;
  if (value === null || value === undefined) {
    return null;
  }
  const language = fieldDefinition?.typeOptions?.code?.language ?? 'text';
  return (
    <Grid container>
      <Grid item xs={12} zeroMinWidth>
      <SyntaxHighlighter wrapLongLines customStyle={{ maxHeight: "800px"}} language={language}>{value}</SyntaxHighlighter>
      </Grid>
    </Grid>
  );
}
