import { EntityDefinition, FieldDefinition, FieldDefinitionTypeOptionEntity } from '../entityTypes';
import { gql } from '@apollo/client';
import { getNameCamelPlural, getNameTitle } from '../entityHelper';

export function buildListQuery(entityDefinition: EntityDefinition, queryNameSuffix: string = '') {
  const name = entityDefinition.name;
  const namePlural = getNameCamelPlural(name);
  const nameTitle = getNameTitle(name);
  const entityFields = buildEntityFields(entityDefinition);
  return gql(`
    query ${nameTitle}${queryNameSuffix}ListQuery(
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
    $orderBy: ${nameTitle}OrderBy
    $filters: ${nameTitle}Filters
  ) {
    ${namePlural}(
      first: $first
      last: $last
      before: $beforeCursor
      after: $afterCursor
      orderBy: $orderBy
      filters: $filters
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        total
      }
      edges {
        cursor
        node {
          ${entityFields}
        }
      }
    }
  }
  `);
}

export function buildViewQuery(entityDefinition: EntityDefinition) {
  const name = entityDefinition.name;
  const nameTitle = getNameTitle(name);
  const entityFields = buildEntityFields(entityDefinition);
  return gql(`
    query ${nameTitle}ViewQuery($id: ID!) {
      ${name}(id: $id) {
        ${entityFields}
      }
    }
  `);
}

export function buildCreateQuery(entityDefinition: EntityDefinition) {
  const name = entityDefinition.name;
  const nameTitle = getNameTitle(name);
  const entityFields = buildEntityFields(entityDefinition);
  return gql(`
    mutation ${nameTitle}Create($input: ${nameTitle}CreateInput!) {
      create${nameTitle}(input: $input) {
        ${name} {
          ${entityFields}
        }
      }
    }
  `);
}

export function buildUpdateQuery(entityDefinition: EntityDefinition) {
  const name = entityDefinition.name;
  const nameTitle = getNameTitle(name);
  const entityFields = buildEntityFields(entityDefinition);
  return gql(`
    mutation ${nameTitle}Update($input: ${nameTitle}UpdateInput!) {
      update${nameTitle}(input: $input) {
        ${name} {
          ${entityFields}
        }
      }
    }
  `);
}

export function buildDeleteQuery(entityDefinition: EntityDefinition) {
  const name = entityDefinition.name;
  const nameTitle = getNameTitle(name);
  return gql(`
    mutation ${nameTitle}Delete($input: ${nameTitle}DeleteInput!) {
      delete${nameTitle}(input: $input) {
        id
      }
    }
  `);
}

export function buildEntityFields(entityDefinition: EntityDefinition) {
  return entityDefinition.fields
    .filter((field) => field.type !== 'custom')
    .map(buildFieldString)
    .join('\n');
}

export function buildFieldString(fieldDefinition: FieldDefinition): string {
  const argPart = buildFieldArgs(fieldDefinition);
  const fieldNamePart = `${fieldDefinition.name}${argPart}`;
  if (fieldDefinition.type === 'entity') {
    const idField = fieldDefinition.typeOptions.entity?.id;
    const valueField = fieldDefinition.typeOptions.entity?.value;
    return `${fieldNamePart} { ${idField} ${valueField} }`;
  }
  return `${fieldNamePart}`;
}

export function buildFieldArgs(fieldDefinition: FieldDefinition): string {
  if (fieldDefinition.fieldArgs.length === 0) {
    return '';
  }

  const fieldArgs = fieldDefinition.fieldArgs
    .map((fieldArg) => {
      return `${fieldArg.name}: "${fieldArg.value}"`;
    })
    .join(', ');
  return `(${fieldArgs})`;
}

export function buildCreateRefQuery(fieldDefinitionTypeOptionEntity: FieldDefinitionTypeOptionEntity) {
  const name = fieldDefinitionTypeOptionEntity.type;
  const nameTitle = getNameTitle(name);
  return gql(`
    mutation ${nameTitle}Create($input: ${nameTitle}CreateInput!) {
      create${nameTitle}(input: $input) {
        ${name} {
          ${fieldDefinitionTypeOptionEntity.value}
        }
      }
    }
  `);
}
