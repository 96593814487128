import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ColumnVisibility, FilterChanges } from './tableTypes';
import { SiteTableActionColumnSelector } from './SiteTableActionColumnSelector';
import { SiteTableDatePickerMenu } from './SiteTableDatePickerMenu';
import { SiteTableSearchBar } from './SiteTableSearchBar';
import { SiteTableExport } from './SiteTableExport';
import { EntityDefinition } from '../entity/entityTypes';
import { CreateEntityButton } from '../entity/actions/CreateEntityButton';
import { doesRoleHaveAccess, useRole } from '../zones/zoneHelper';
import { CustomTableAction, StandardTableActions } from './SiteTableTypes';

export interface Props<EntityKind> {
  entityDefinition: EntityDefinition;
  filters: object;
  updateFilters: (filterChanges: FilterChanges) => void;
  rows: Array<EntityKind>;
  columnVisibilities: Array<ColumnVisibility>;
  setColumnVisibilities: (columns: Array<ColumnVisibility>) => void;
  onDateRangeChange: (from?: Date, to?: Date) => void;
  reload: () => void;
  createParams?: Record<string, string>;
  standardTableActions?: StandardTableActions;
  customTableActions?: CustomTableAction[];
}

const useStyles = makeStyles((theme) => ({
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  inputUppercase: {
    textTransform: 'uppercase'
  },
  block: {
    display: 'block'
  },
  addAction: {
    marginRight: theme.spacing(1)
  },
  appBar: {
    // borderRadius: '8px 8px 0 0'
  },
  appBarFocused: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#FFFFFF'
  },
  appBarDefault: {
    // color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.common.white
  },
  toolBar: {
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)'
  }
}));

export function SiteTableActionBar<EntityKind>(props: Props<EntityKind>) {
  const {
    entityDefinition,
    filters,
    updateFilters,
    columnVisibilities,
    setColumnVisibilities,
    onDateRangeChange,
    reload,
    rows,
    createParams,
    standardTableActions = {},
    customTableActions = []
  } = props;
  const classes = useStyles();
  const role = useRole();
  const {
    enableSearch = true,
    enableCalendarFilter = true,
    enableExport = true,
    enableColumnEdit = true,
    enableRefresh = true,
    enableCreate = true
  } = standardTableActions;
  const isAdmin = doesRoleHaveAccess('ADMIN', role);
  const isCreateEnabled =
    enableCreate && entityDefinition.pages.create.enabled && entityDefinition.pages.list.canCreate && isAdmin;
  const isBarEnabled =
    enableSearch || enableCalendarFilter || enableExport || enableColumnEdit || enableRefresh || isCreateEnabled;
  if (!isBarEnabled) {
    return null;
  }
  return (
    <AppBar className={`${classes.appBar} ${classes.appBarDefault}`} position="static" elevation={0}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          {enableSearch ? (
            <Grid item>
              <SiteTableSearchBar entityDefinition={entityDefinition} updateFilters={updateFilters} />
            </Grid>
          ) : null}
          {isCreateEnabled ? (
            <Grid item>
              <CreateEntityButton
                entityDefinition={entityDefinition}
                createParams={createParams}
                size="small"
                variant="outlined"
              />
            </Grid>
          ) : null}
          {customTableActions.map((CustomTableAction, i) => {
            return (
              <Grid key={`custom-table-action-${i}`} item>
                <CustomTableAction entityDefinition={entityDefinition} filters={filters} updateFilters={updateFilters} rows={rows} columnVisibilities={columnVisibilities}  reload={reload} />
              </Grid>
            );
          })}
          {enableCalendarFilter ? (
            <Grid item>
              <SiteTableDatePickerMenu onDateRangeChange={onDateRangeChange} />
            </Grid>
          ) : null}
          {enableExport ? (
            <Grid item>
              <SiteTableExport entityDefinition={entityDefinition} columnVisibilities={columnVisibilities} rows={rows} />
            </Grid>
          ) : null}
          {enableColumnEdit ? (
            <Grid item>
              <SiteTableActionColumnSelector
                columnVisibilities={columnVisibilities}
                setColumnVisibilities={setColumnVisibilities}
              />
            </Grid>
          ) : null}
          {enableRefresh ? (
            <Grid item>
              <IconButton onClick={reload}>
                <RefreshIcon className={classes.block} color="inherit" />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
