import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface SiteSideBarCategoryProps {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  categoryHeaderPrimary: {
    color: theme.palette.primary.contrastText
  }
}));

export function SiteSideBarCategory(props: SiteSideBarCategoryProps) {
  const { label } = props;
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        classes={{
          primary: classes.categoryHeaderPrimary
        }}
      >
        {label}
      </ListItemText>
    </ListItem>
  );
}
