import React from 'react';
import SitePage from '../../site/SitePage';
import { EntityDefinition } from '../entityTypes';
import { buildCreateQuery } from './entityQueryBuilder';
import { EntityCreateCard } from '../EntityCreateCard';

export interface Props {
  entityDefinition: EntityDefinition;
}

export function EntityCreatePage(props: Props) {
  const { entityDefinition } = props;
  const createQuery = buildCreateQuery(entityDefinition);
  return (
    <SitePage title={`New ${entityDefinition.label}`}>
      <EntityCreateCard entityDefinition={entityDefinition} createQuery={createQuery} />
    </SitePage>
  );
}
