import React from 'react';
import SitePage from '../site/SitePage';
import { Grid } from '@material-ui/core';
import MyProfileForm from './MyProfileForm';
import MyProfileCard from './MyProfileCard';

export default function MyProfilePage() {
  return (
    <SitePage title="Profile">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <MyProfileCard />
        </Grid>
        <Grid item xs={12} md={6} lg={8} xl={9}>
          <MyProfileForm />
        </Grid>
      </Grid>
    </SitePage>
  );
}
