import { EntityDefinition } from '../../entity/entityTypes';
import { createEntityDefinition } from '../../entity/entityHelper';
import { registerEntityDefinition } from '../../entity/entityDefinitionService';
import { TrackFromFastTrackTrackButton } from './TrackFromFastTrackTrackButton';

export const fastTrackTrackDefinition: EntityDefinition = createEntityDefinition({
  name: 'fastTrackTrack',
  label: 'Fast Track Track',
  pages: {
    view: {
      actions: [TrackFromFastTrackTrackButton]
    },
    list: {
      orderBy: 'name_ASC'
    }
  },
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'string',
      tableOptions: {
        enabled: false
      },
      viewOptions: {
        enabled: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      typeOptions: {
        string: {
          changeCase: 'uppercase'
        }
      },
      editOptions: {
        mandatory: true
      },
      tableOptions: {
        sortable: true,
        canFilter: true
      },
      viewOptions: {
        selfLink: true
      }
    },
    {
      name: 'trackId',
      label: 'Track Id',
      type: 'string',
      editOptions: {
        mandatory: true
      }
    },
    {
      name: 'state',
      label: 'State',
      type: 'string'
    },
    {
      name: 'code',
      label: 'Code',
      type: 'string'
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      tableOptions: {
        sortable: true,
        canFilter: true,
        visibleByDefault: false
      },
      editOptions: {
        enabled: false
      }
    }
  ]
});

registerEntityDefinition('fastTrackTrack', fastTrackTrackDefinition);
